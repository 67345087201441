import config from '@/__config'

const _menu = {}

if (config.wsmodule.file_system.drive) {
  _menu.drive = {
    icon: 'icon-md-cloud',
    title: 'Drive',
    link: '/drive',
    scopes: ['file_system-admin'],
  }
}

export default _menu