import config from '@/__config'
import i18n from '@/i18n.js';

const model = {
  options: {
    id: true,
    // site_region: true,
  },
  modelName: config.wsmodule.admin.admin_blur ? 'cmser' : "admin",
  label: i18n.t("後台使用者"),
  fields: {
    name: {
      type: 'text',
      label: i18n.t('名稱'),
      rules: 'required',
      autofocus: true,
    },
    email: {
      type: 'email',
      label: i18n.t('Email'),
      rules: 'required'
    },
    password: {
      type: 'password',
      label: i18n.t('密碼'),
      rules: {
        create: 'required',
        update: ''
      },
    },
    avatar: {
      type: 'file_image',
      label: '頭像'
    },
    is_active: {
      type: 'switch',
      label: i18n.t('啟用'),
      defaultValue: true,
    },
    scopes: {
      type: 'scope',
      label: i18n.t('帳號權限'),
      searchable: true,
      selectAll: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "id",
        "name",
        "email",
        "is_active",
      ]
    }
  }
}

if (config.wsmodule.admin.admin_group) {
  if (config.wsmodule.admin.admin_blur) {
    model.fields.cmser_groups = {
      type: 'belongsToMany',
      label: i18n.t('後台使用者分群'),
      modelName: 'cmser_group',
      labelInLocale: true,
    }
    model.crudSetting.index.showFields.push("cmser_groups")
  } else {
    model.fields.admin_groups = {
      type: 'belongsToMany',
      label: i18n.t('後台使用者分群'),
      modelName: 'admin_group',
      labelInLocale: true,
    }
    model.crudSetting.index.showFields.push("admin_groups")
  }
}
if (config.wsmodule.admin.admin_role) {
  model.fields.roles = {
    type: 'belongsToMany',
    label: i18n.t('角色'),
    modelName: config.wsmodule.admin.admin_blur ? 'cmser_role' : "admin_role",
  }
}
if (config.wsmodule.admin.fields) {
  if (config.wsmodule.admin.fields.email_verified_at) {
    model.fields.email_verified_at = {
      type: 'datetime',
      label: i18n.t('信箱認證時間')
    }
  }
  if (config.wsmodule.admin.fields.github) {
    model.fields.github = {
      type: 'text',
      label: i18n.t('GitHub 帳號'),
    }
  }
  if (config.wsmodule.admin.fields.gitlab) {
    model.fields.gitlab = {
      type: 'text',
      label: i18n.t('GitLab 帳號'),
    }
  }
  if (config.wsmodule.admin.fields.color) {
    model.fields.color = {
      type: 'color',
      label: i18n.t('顏色')
    }
  }
  if (config.wsmodule.admin.fields.ssh_public_key) {
    model.fields.ssh_public_key = {
      type: 'text',
      label: i18n.t('SSH Public Key'),
    }
  }
  if (config.wsmodule.admin.fields.identity_id) {
    model.fields.identity_id = {
      type: 'text',
      label: i18n.t('身分證字號'),
    }
  }
  if (config.wsmodule.admin.fields.mobile) {
    model.fields.mobile = {
      type: 'text',
      label: i18n.t('手機'),
    }
  }
  if (config.wsmodule.admin.fields.working_email) {
    model.fields.working_email = {
      type: 'text',
      label: i18n.t('工作信箱'),
    }
  }
  if (config.wsmodule.admin.fields.personal_email) {
    model.fields.personal_email = {
      type: 'text',
      label: i18n.t('個人信箱'),
    }
  }
  if (config.wsmodule.admin.fields.identity_card_front) {
    model.fields.identity_card_front = {
      type: 'file_image',
      label: i18n.t('身分證正面'),
      storageTarget: 'admin'
    }
  }
  if (config.wsmodule.admin.fields.identity_card_back) {
    model.fields.identity_card_back = {
      type: 'file_image',
      label: i18n.t('身分證反面'),
      storageTarget: 'admin'
    }
  }
  if (config.wsmodule.admin.fields.health_card_front) {
    model.fields.health_card_front = {
      type: 'file_image',
      label: i18n.t('健保卡正面'),
      storageTarget: 'admin'
    }
  }
  if (config.wsmodule.admin.fields.relate_infos) {
    model.fields.relate_infos = {
      type: 'list',
      label: i18n.t('相關資訊'),
      fields: {
        name: {
          type: 'text',
          label: i18n.t('資訊名稱')
        },
        content: {
          type: 'textarea',
          label: i18n.t('內容')
        }
      }
    }
  }
}

if (config.wsmodule.country_code) {
  model.fields['country_code'] = {
    type: "select",
    label: i18n.t("國家/地區代碼"),
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  }
  model.crudSetting.index.showFields.push('country_code')
}

export default model