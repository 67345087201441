export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_position',
  label: '職位管理',
  fields: {
    name: {
      label: '名稱',
      type: 'text',
      rules: 'required',
      autofocus: true,
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      showFields: [
        'name',
      ],
      customTableActions: [
        {
          icon: 'icon-bih-relatives',
          emit: 'update-admins',
          tooltip: '人員管理',
        },
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'title',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'name',
      ],
      titleKey: 'name',
    },
  }
}