<template>
  <div>
    <WsFlex :gap="12">
      <WsBtn @click="$_onUploadClick()">選擇匯入檔案</WsBtn>
      <WsBtn
        v-if="getImportformatDownloadUrl"
        @click="$_onImportformatdownload()"
      >匯入格式下載</WsBtn>
    </WsFlex>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="*/*"
    >
    <WsText
      v-if="value"
      class="mt-20"
    >{{value.name}}</WsText>
  </div>
</template>

<script>
export default {
  methods: {
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    async $_onImportformatdownload() {
      this.$store.dispatch("app/startPageLoading");
      try {
        const res = await this.$axios.get(this.getImportformatDownloadUrl);
        window.open(res.data, "_blank");
      } catch (error) {
        alert("匯出發生錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onChange($event) {
      this.$emit("input", $event.target.files[0]);
    },
  },
  props: {
    value: {},
    getImportformatDownloadUrl: {},
  },
};
</script>

<style>
</style>