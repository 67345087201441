const model = {
  options: {
    id: true,
    // timestamp: true,
  },
  modelName: 'testing_case',
  label: 'TestCase',
  fields: {
    testing_case_template: {
      type: 'belongsTo',
      label: 'TestCase Template',
      modelName: 'testing_case_template',
      async setStateOnInput(state, $event, controller) {
        const res = await controller.$axios.get(`/testing_case_template/${$event.id}`);
        const _testing_case_template = res.data.data
        state.name = _testing_case_template.name;
        state.des = _testing_case_template.des;
        state.condition = _testing_case_template.condition;
        state.priority = _testing_case_template.priority;
        state.testing_case_items = []
        for (let i = 0; i < _testing_case_template.testing_case_template_items.length; i++) {
          const testing_case_template_item = _testing_case_template.testing_case_template_items[i];
          state.testing_case_items.push({
            des: testing_case_template_item.des,
          });
        }
        return state;
      },
    },
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required',
    },
    des: {
      type: 'textarea',
      label: '描述',
      autofocus: true,
    },
    condition: {
      type: 'textarea',
      label: '前置條件',
    },
    priority: {
      type: 'radio',
      label: '優先度',
      defaultValue: 'p1',
      items: [
        { value: 'p1', label: 'p1' },
        { value: 'p2', label: 'p2' },
        { value: 'p3', label: 'p3' },
      ]
    },
    testing_case_items: {
      type: 'hasMany',
      label: '測試項目',
      modelName: 'testing_case_item',
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
      ],
      inRowBtnRead: true,
      pageMode: true,
    },
    read: {
      titleKey: 'name',
      customBtns: [
        {
          label: 'Pass',
          onClick: (controller, modeldata, id) => {
            controller.$store.dispatch("app/setStateDialog", {
              fields: {
                des: model.fields.des
              },
              data: {
                id: id,
                item: modeldata,
              },
              title: "Pass",
              onSubmit: async (state, data, controller) => {
                try {
                  controller.$store.dispatch("app/startPageLoading");
                  await controller.$axios.post(`/testing_case/${data.id}/pass`, {
                    des: state.des,
                  });
                  controller.$store.dispatch("app/addSnack", `送出成功`);
                } catch (error) {
                  controller.$o_o.$h.http.getErrorMessageFromRes(error);
                } finally {
                  controller.$store.dispatch("app/stopPageLoading");
                }
              },
            });
          }
        },
        {
          label: 'Fail',
          outlined: true,
          onClick: (controller, modeldata, id) => {
            controller.$store.dispatch("app/setStateDialog", {
              fields: {
                des: model.fields.des
              },
              data: {
                id: id,
                item: modeldata,
              },
              title: "Fail",
              onSubmit: async (state, data, controller) => {
                try {
                  controller.$store.dispatch("app/startPageLoading");
                  await controller.$axios.post(`/testing_case/${data.id}/fail`, {
                    des: state.des,
                  });
                  controller.$store.dispatch("app/addSnack", `送出成功`);
                } catch (error) {
                  controller.$o_o.$h.http.getErrorMessageFromRes(error);
                } finally {
                  controller.$store.dispatch("app/stopPageLoading");
                }
              },
            });
          }
        },
      ],
      leftFields: [
        "testing_case_items",
      ],
      rightFields: [
        "testing_case_template",
        "name",
        "des",
        "condition",
        "priority",
      ],
      childCruds: [
        {
          modelName: 'testing_record',
          inRowBtnRead: false,
          creatable: false,
          deletable: false,
          updatable: false,
        },
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "testing_case_items",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "testing_case_template",
                "priority",
                "name",
                "des",
                "condition",
              ]
            }
          ]
        }
      ]
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "testing_case_items",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "testing_case_template",
                "priority",
                "name",
                "des",
                "condition",
              ]
            }
          ]
        }
      ]
    }
  }
}

export default model