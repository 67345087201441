import config from '@/__config'
import H_Menu from "@/__stone/helpers/menu";

export default {
  namespaced: true,
  state: {
    needSave: false,
    sideMenuOpen: false,
    appBarSaveLoading: false,
    pageLoading: false,
    hasBottomNav: false,
    hasDrawer: config.theme.hasDrawer,
    hasHeader: config.theme.hasHeader,
    theme: config.theme.mode,
    snack: [],
    stateDialog: false,
    alert: {
      title: '',
      description: '',
      action: null,
      data: {}
    },
    topic: H_Menu.getInitTopicKey(),
    modalMenu: {
      active: false,
      items: []
    },
    screenSizeMode: null,
    cursor: {
      pageX: 0,
      pageY: 0
    },
    touch: {
      clientX: 0,
      clientY: 0,
    },
    window: {
      width: null,
      height: null,
      devicePixelRatio: 1
    },
    body: {
      scrollTop: 0
    },
    imagePreviewPopup: {
      active: false,
      imageUrl: ''
    },
    paperclip: null
  },
  mutations: {
    SET_STATE_DIALOG(state, data) {
      state.stateDialog = data
    },
    SET_SCREEN_SIZE_MODE(state, data) {
      state.screenSizeMode = data
    },
    SET_MODAL_MENU_ITEMS(state, data) {
      state.modalMenu.items = data
    },
    OPEN_MODAL_MENU(state) {
      state.modalMenu.active = true
    },
    CLOSE_MODAL_MENU(state) {
      state.modalMenu.active = false
    },
    SET_ALERT(state, data) {
      state.alert.title = data.title
      state.alert.description = data.description
      state.alert.action = data.action
      state.alert.data = data.data
    },
    SET_TOPIC(state, data) {
      state.topic = data
    },
    CLEAR_ALERT(state) {
      state.alert.title = ""
      state.alert.description = ""
      state.alert.action = null
    },
    STOP_NEED_SAVE(state) {
      state.needSave = false;
    },
    START_NEED_SAVE(state) {
      state.needSave = true;
    },
    START_APP_BAR_SAVE_LOADING(state) {
      state.appBarSaveLoading = true;
    },
    STOP_APP_BAR_SAVE_LOADING(state) {
      state.appBarSaveLoading = false;
    },
    START_PAGE_LOADING(state) {
      state.pageLoading = true;
    },
    STOP_PAGE_LOADING(state) {
      state.pageLoading = false;
    },
    MENU_TOGGLE(state) {
      state.sideMenuOpen = !state.sideMenuOpen;
    },
    MENU_CLOSE(state) {
      state.sideMenuOpen = false;
    },
    MENU_OPEN(state) {
      state.sideMenuOpen = true;
    },
    HAS_BOTTOM_NAV_ACTIVE(state) {
      state.hasBottomNav = true
    },
    HAS_BOTTOM_NAV_DEACTIVE(state) {
      state.hasBottomNav = false
    },
    SET_THEME(state, data) {
      state.theme = data
    },
    ADD_SNACK(state, data) {
      state.snack.push(data)
      setTimeout(() => {
        state.snack.shift()
      }, config.general.snackTimeout);
    },
    SET_CURSOR(state, data) {
      state.cursor.pageX = data.pageX;
      state.cursor.pageY = data.pageY;
    },
    SET_TOUCH(state, data) {
      state.touch.clientX = data.clientX;
      state.touch.clientY = data.clientY;
    },
    SET_WINDOW(state, data) {
      state.window.width = data.innerWidth;
      state.window.height = data.innerHeight;
      state.window.devicePixelRatio = data.devicePixelRatio;
    },
    SET_BODY_SCROLLTOP(state, data) {
      state.body.scrollTop = data;
    },
    SET_IMAGEPREVIEWPOPUP(state, data) {
      state.imagePreviewPopup.active = true
      state.imagePreviewPopup.imageUrl = data.imageUrl
    },
    CLEAR_IMAGEPREVIEWPOPUP(state) {
      state.imagePreviewPopup.active = false
      state.imagePreviewPopup.imageUrl = ''
    },
    SET_PAPERCLIP(state, data) {
      state.paperclip = data;
    }
  },
  actions: {
    setStateDialog({ commit }, data) {
      commit('SET_STATE_DIALOG', data)
    },
    setScreenSizeMode({ commit }, data) {
      commit('SET_SCREEN_SIZE_MODE', data)
    },
    setModalMenuItems({ commit }, data) {
      commit('SET_MODAL_MENU_ITEMS', data)
    },
    openModalMenu({ commit }) {
      commit('OPEN_MODAL_MENU')
    },
    closeModalMenu({ commit }) {
      commit('CLOSE_MODAL_MENU')
    },
    addSnack({ commit }, data) {
      commit('ADD_SNACK', data)
    },
    setTopic({ commit }, data) {
      localStorage.setItem("topic", data);
      commit('SET_TOPIC', data)
    },
    setAlert({ commit }, data) {
      commit('SET_ALERT', data)
    },
    clearAlert({ commit }) {
      commit('CLEAR_ALERT')
    },
    startAppBarSaveLoading({ commit }) {
      commit('START_APP_BAR_SAVE_LOADING', true);
    },
    stopAppBarSaveLoading({ commit }) {
      commit('STOP_APP_BAR_SAVE_LOADING', false);
    },
    startPageLoading({ commit }) {
      commit('START_PAGE_LOADING', true);
    },
    stopPageLoading({ commit }) {
      commit('STOP_PAGE_LOADING', false);
    },
    startNeedSave({ commit }) {
      commit('START_NEED_SAVE');
    },
    stopNeedSave({ commit }) {
      commit('STOP_NEED_SAVE');
    },
    menuToggle({ commit }) {
      commit('MENU_TOGGLE');
    },
    menuOpen({ commit }) {
      commit('MENU_OPEN');
    },
    menuClose({ commit }) {
      commit('MENU_CLOSE');
    },
    hasBottomNavActive({ commit }) {
      commit('HAS_BOTTOM_NAV_ACTIVE');
    },
    hasBottomNavDeactive({ commit }) {
      commit('HAS_BOTTOM_NAV_DEACTIVE');
    },
    setTheme({ commit }, data) {
      commit('SET_THEME', data)
    },
    setCursor({ commit }, data) {
      commit("SET_CURSOR", data);
    },
    setTouch({ commit }, data) {
      commit("SET_TOUCH", data);
    },
    setWindow({ commit }, data) {
      commit("SET_WINDOW", data);
    },
    setBodyScrollTop({ commit }, data) {
      commit("SET_BODY_SCROLLTOP", data);
    },
    setImagePreviewPopup({ commit }, data) {
      commit('SET_IMAGEPREVIEWPOPUP', data)
    },
    clearImagePreviewPopup({ commit }) {
      commit('CLEAR_IMAGEPREVIEWPOPUP')
    },
    setPaperclip({ commit }, data) {
      commit('SET_PAPERCLIP', data)
    },
    clearPaperclip({ commit }) {
      commit('SET_PAPERCLIP', null)
    },
  },
}