export default {
  options: {
    // id: true,
    timestamp: true,
  },
  modelName: "quotation",
  label: "報價單",
  fields: {
    no: {
      type: 'text',
      label: '報價單號',
      readonly: true
    },
    name: {
      type: 'text',
      label: '專案名稱'
    },
    client_name: {
      type: 'text',
      label: '自訂客戶名稱'
    },
    creator_name: {
      type: 'text',
      label: '負責人員'
    },
    contact_window: {
      type: 'text',
      label: '聯絡窗口'
    },
    contact_window_email: {
      type: 'text',
      label: '電子郵件'
    },
    has_return_sign: {
      type: 'switch',
      label: '回簽欄位'
    },
    quote_at: {
      type: 'date',
      label: '報價日期'
    },
    expected_start_at: {
      type: 'date',
      label: '執行日期'
    },
    has_total_amount: {
      type: 'switch',
      label: '總計'
    },
    execute_items: {
      type: 'list',
      label: "執行項目",
      fields: {
        name: {
          type: 'text',
          label: '項目名稱'
        },
        content: {
          type: 'textarea',
          label: '內容文字'
        },
        // excute_days: {
        //   type: 'number',
        //   label: '執行天數'
        // },
        price: {
          type: 'number',
          label: '價格'
        },
      }
    },
    des_items: {
      type: 'list',
      label: '說明項目',
      fields: {
        content: {
          type: 'textarea',
          label: '內容文字'
        }
      }
    },
  },
  crudSetting: {
    index: {
      // showFields: [
      //   'id',
      //   'pay_type',
      //   'amount',
      //   'pay_at',
      //   'admin',
      //   'review_status',
      // ],
      pageMode: true,
      duplicatable: true,
      customTableActions: [
        {
          icon: "icon-md-print",
          tooltip: 'Print',
          getUrl: (item) => {
            return `/print/quotation/${item.id}`
          }
        }
      ]
      // creatable: false,
      // showFieldsUpdate: [
      //   "review_status",
      //   "review_remark",
      // ]
      // updatable: false,
    },
  }
}