<template>
  <div class="width-full">
    Show
    <!-- <WsActionApi
      :fetchUrl="`xc_project/${xc_project.id}/member_month_days`"
      :params="_params"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <XcProjectMemberMonthDayReadTable
        v-if="res"
        ref="XcProjectMemberMonthDayReadTable"
        :items="res.member_month_days"
        :start_at="res.start_at"
        :end_at="res.end_at"
      />
    </WsActionApi>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('更新')}}</WsBtn>
      </template>
    </WsBottomNav> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["admin", "scopes"],
    };
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        const _state = this.$refs.XcProjectMemberMonthDayReadTable.getState();
        await this.$axios.patch(
          `xc_project/${this.xc_project.id}/member_month_days`,
          {
            member_month_days: _state,
          }
        );
        this.$store.dispatch("app/addSnack", this.$t("更新完成"));
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  props: {
    xc_project: {},
  },
  computed: {
    _params() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _createData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _updateData() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _creatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
    _updatable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
    _deletable() {
      return this.$o_o.$h._m.xc_project.hasScope(
        ["admin"],
        this.xc_project.admin_scope
      );
    },
  },
};
</script>

<style>
</style>