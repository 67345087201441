export default {
  getXcOfficialLeaveShowFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = []
    }
    _res = [
      ..._res,
      'xc_project'
    ]
    return _res
  }
}