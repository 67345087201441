<template>
  <div class="ws-state-model-batch">
    <WsCard
      class="my-8 posi-relative"
      :color="$color.black3l"
      v-for="(model, modelIndex) in value"
      :key="modelIndex"
    >
      <WsIconBtnClose
        @click="$_onClickRemove(modelIndex)"
        class="posi-absolute right-0 top-0"
      ></WsIconBtnClose>
      <WsStateForm
        :fields="_fields"
        :value="model"
        @input="$_onInputModel(modelIndex, $event)"
      ></WsStateForm>
    </WsCard>
    <WsBtn @click="$_onClickNew()">{{$t('新增')}}</WsBtn>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInputModel(modelIndex, $event) {
      const _value = [...this.value];
      _value[modelIndex] = {
        ..._value[modelIndex],
        ...$event,
      };
      this.$emit("input", _value);
    },
    $_onClickRemove(modelIndex) {
      console.log("modelIndex", modelIndex);
      const _value = [...this.value];
      _value.splice(modelIndex, 1);
      this.$emit("input", _value);
    },
    $_onClickNew() {
      const _value = [...this.value];
      _value.push({});
      this.$emit("input", _value);
    },
  },
  computed: {
    _fields() {
      return this.$o_o.$h.model.getUpdateFields(
        this.$store.state.stone_model[this.modelName].fields
      );
    },
  },
  props: {
    modelName: {},
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>