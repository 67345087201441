<template>
  <WsMain>
    <WsActionApi
      :fetchUrl="`/my/xc_employee`"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <WsInfoForm
        v-else
        :fields="_fields"
        :value="res.data"
      ></WsInfoForm>
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {
  computed: {
    _fields() {
      const _fields = { ...this.$store.state.stone_model.xc_employee.fields };
      delete _fields.tw_health_insurance_employer;
      delete _fields.tw_labor_insurance_employer;
      delete _fields.tw_labor_pension_ref_amount;
      delete _fields.tw_labor_pension_employer;
      delete _fields.tw_occupational_accident_insurance_ref_amount;
      delete _fields.tw_occupational_accident_insurance_employer;
      return _fields;
    },
  },
};
</script>

<style>
</style>