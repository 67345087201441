import serviceAuth from '@/__stone/service/api/v1/auth'
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import config from '@/__config'
import message from '@/__stone/message'
import * as Case from "change-case";
import $o_o from '@/__stone'
import $h_router from '@/__stone/helpers/router'

export default (app) => {
  app.prototype.$auth = serviceAuth;
  axios.defaults.baseURL = $h_router.getApiUrl()
  app.prototype.$axios = axios;
  app.prototype.$moment = moment;
  app.prototype.$numeral = numeral;
  app.prototype.$config = config;
  app.prototype.$message = message[config.locale.lang];
  app.prototype.$case = Case;
  app.prototype.$o_o = $o_o;
  // loadXSpreadsheetResources(app)

}

// const loadXSpreadsheetResources = (app) => {
//   return new Promise((resolve, reject) => {
//     // Load the CSS for x-data-spreadsheet
//     const cssLink = document.createElement('link');
//     cssLink.rel = 'stylesheet';
//     cssLink.href = 'https://unpkg.com/x-data-spreadsheet@1.1.5/dist/xspreadsheet.css';
//     document.head.appendChild(cssLink);

//     // Load the JS script for x-data-spreadsheet
//     const script = document.createElement('script');
//     script.src = 'https://unpkg.com/x-data-spreadsheet@1.1.5/dist/xspreadsheet.js';
//     script.onload = () => {
//       resolve(window.x_spreadsheet);
//       app.prototype.$x_spreadsheet = window.x_spreadsheet;
//     }; // Resolve with x_spreadsheet when loaded
//     script.onerror = (error) => reject(error);
//     document.body.appendChild(script);
//   });
// };