<template>
  <LayoutModelCreate
    v-bind="$store.state.stone_model.testing_case"
    :extendPostData="_extendPostData"
    :completeRedirectUrl="`/xc_project/${this.$route.params.id}/testing_case`"
  ></LayoutModelCreate>
</template>

<script>
export default {
  computed: {
    _extendPostData() {
      return {
        xc_project: this.$route.params.id,
      };
    },
  },
};
</script>

<style>
</style>