<template>
  <div class="ws-info-model-batch w-full">
    <WsCard
      class="my-8 posi-relative"
      :color="$color.black3l"
      v-for="(model, modelIndex) in value"
      :key="modelIndex"
    >
      <WsInfoForm
        :fields="_fields"
        :value="model"
      ></WsInfoForm>
    </WsCard>
  </div>
</template>

<script>
export default {
  computed: {
    _fields() {
      return this.$o_o.$h.model.getUpdateFields(
        this.$store.state.stone_model[this.modelName].fields
      );
    },
  },
  props: {
    modelName: {},
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
// .ws-info-model-batch {
//   .ws-card {
//     margin-top: 8px;
//     position: relative;
//   }
// }
</style>