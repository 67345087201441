<template>
  <div class="ws-file-state-image">
    <div v-if="value && value.length">
      <WsFlex
        v-for="(valueItem, valueItemIndex) in value"
        :key="valueItemIndex"
        :gap="10"
        class="ws-file-state-image"
      >
        <WsLink
          v-if="valueItem"
          class="mb-10 mt-10"
          :href="valueItem"
        >{{ $_getFileName(valueItem) }}</WsLink>
        <WsIconBtn
          @click="$_onClear(valueItemIndex)"
          backgroundColor="#000"
          name="icon-md-delete"
        />
      </WsFlex>
    </div>
    <FileSystemFilePickerFile
      :signed="signed"
      :storageTarget="storageTarget"
      ref="FileSystemFilePickerFile"
      @submit="$_onSubmit($event)"
      :pocketable="pocketable"
      multiple
    >
    </FileSystemFilePickerFile>
    <WsBtn @click="$_onPickDialogOpen()">選擇檔案</WsBtn>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    $_getFileName(valueItem) {
      if (!valueItem) {
        return null;
      }
      return decodeURIComponent(valueItem).split("?")[0].split("/").pop();
    },
    $_onSubmit($event) {
      const _value = this.value ? [...this.value, ...$event] : [...$event];
      console.log("_value", _value);
      this.$emit("input", _value);
    },
    $_onClear(valueIndex) {
      const _value = [...this.value];
      _value.splice(valueIndex, 1);
      this.$emit("input", _value);
    },
    $_onPickDialogOpen() {
      this.$refs.FileSystemFilePickerFile.open();
    },
  },

  props: {
    value: {},
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
    storageTarget: {},
    pocketable: {},
    maxSize: {
      default: 5 * 1024 * 1024,
    },
  },
};
</script>

<style></style>