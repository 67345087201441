var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ws-flex",class:[
    {'is-wrap':_vm.isWrap},
    {'is-inline':_vm.isInline},
  ],style:([
    {'flex-direction':_vm.flexDirection},
    {'align-items':_vm.alignItems},
    {'justify-content':_vm.justifyContent},
    {'gap':_vm._gap}
  ])},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }