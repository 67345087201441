<template>
  <WsIcon
    class="folder-icon"
    :size="size"
    name="icon-md-folder"
  ></WsIcon>
</template>

<script>
export default {
  props: {
    ext: {},
    size: {
      default: 24,
    },
  },
};
</script>

<style>
.folder-icon {
  color: var(--primary);
}
</style>