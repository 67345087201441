import axios from 'axios'

export default {
  async mail(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('testing/mail', data)
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async fcm(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('testing/fcm', data)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}