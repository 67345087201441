<template>
  <div>
    <WsActionApi
      class="width-full"
      :fetchUrl="_fetchUrl"
      @fetch="$_onFetch($event)"
    >
      <!-- {{ res }} -->
      <WsExcel
        ref="WsExcel"
        :fields="_fields"
        :value="_value"
        :readonly="true"
        :exportFilename="_exportFilename"
      ></WsExcel>
    </WsActionApi>
    <WsFixedIconBtns :btns="btns"></WsFixedIconBtns>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xc_work_overtimes: [],
      btns: [
        {
          icon: "icon-md-print",
          text: "匯出",
          size: 60,
          onClick: () => {
            this.$_print();
          },
        },
      ],
    };
  },
  methods: {
    $_onFetch($event) {
      this.xc_work_overtimes = $event.data;
    },
    $_print() {
      this.$refs.WsExcel.exportExcel();
    },
    $_getSlectValueText(value, items) {
      const item = items.find((item) => item.value === value);
      return item ? item.text : "";
    },
    $_getFormatedData(data) {
      return data.map((item) => {
        return {
          xc_employee_department: item.xc_employee.xc_employee_department?.name,
          xc_employee_name: item.xc_employee.name,
          xc_employee_type: item.xc_employee.xc_employee_type?.name,
          created_at: this.$moment(item.created_at).format("YYYY-MM-DD"),
        };
      });
    },
  },
  computed: {
    _exportFilename() {
      const _start_f = this.$moment(this._start).format("YYYYMMDD");
      const _end_f = this.$moment(this._end).format("YYYYMMDD");
      return `加班費明細-${this._name}-${_start_f}-${_end_f}`;
    },
    _start() {
      return this.$route.query.start;
    },
    _end() {
      return this.$route.query.end;
    },
    _name() {
      return this.$route.query.name;
    },
    _value() {
      const _value = [];
      for (const xc_work_overtime of this.xc_work_overtimes) {
        let work_overtime_type = "";
        if (xc_work_overtime.work_overtime_type == "weekday") {
          work_overtime_type = "平日加班";
        } else if (xc_work_overtime.work_overtime_type == "restday") {
          work_overtime_type = "休息日加班";
        } else if (xc_work_overtime.work_overtime_type == "official_holiday") {
          work_overtime_type = "國定假日加班";
        }
        _value.push({
          xc_employee_department:
            xc_work_overtime.xc_employee.xc_employee_department?.name,
          xc_employee_name: xc_work_overtime.xc_employee.name,
          work_overtime_type: work_overtime_type,
          start_at: this.$moment(xc_work_overtime.start_at).format(
            "YYYY-MM-DD HH:mm"
          ),
          end_at: this.$moment(xc_work_overtime.end_at).format(
            "YYYY-MM-DD HH:mm"
          ),
          monthly_salary_amount: xc_work_overtime.monthly_salary_amount,
          transportation_allowance: xc_work_overtime.transportation_allowance,
          hourly_salary_amount: xc_work_overtime.hourly_salary_amount,
          hour: xc_work_overtime.hour,
          hour_weekday_1: xc_work_overtime.hour_weekday_1,
          hour_weekday_2: xc_work_overtime.hour_weekday_2,
          hour_restday_1: xc_work_overtime.hour_restday_1,
          hour_restday_2: xc_work_overtime.hour_restday_2,
          hour_restday_3: xc_work_overtime.hour_restday_3,
          hour_official_holiday_1: xc_work_overtime.hour_official_holiday_1,
          hour_official_holiday_2: xc_work_overtime.hour_official_holiday_2,
          hour_official_holiday_3: xc_work_overtime.hour_official_holiday_3,
          amount_weekday: xc_work_overtime.amount_weekday,
          amount_restday: xc_work_overtime.amount_restday,
          amount_official_holiday: xc_work_overtime.amount_official_holiday,
          pay_amount: xc_work_overtime.pay_amount,
        });
      }
      return _value;
    },
    _fields() {
      return {
        xc_employee_department: {
          label: "部門",
          type: "string",
        },
        xc_employee_name: {
          label: "申請人",
          type: "string",
        },
        work_overtime_type: {
          label: "加班類別",
          type: "string",
          width: 100,
        },
        start_at: {
          label: "開始時間",
          type: "string",
          width: 150,
        },
        end_at: {
          label: "結束時間",
          type: "string",
          width: 150,
        },
        monthly_salary_amount: {
          label: "核定月薪",
          type: "string",
        },
        transportation_allowance: {
          label: "交通津貼",
          type: "string",
        },
        hourly_salary_amount: {
          label: "時薪",
          type: "string",
        },
        hour: {
          label: "加班時數",
          type: "string",
        },
        hour_weekday_1: {
          label: "平日前2小時",
          type: "string",
        },
        hour_weekday_2: {
          label: "平日後2小時",
          type: "string",
        },
        hour_restday_1: {
          label: "休息日前2小時",
          type: "string",
        },
        hour_restday_2: {
          label: "休息日2~7.5",
          type: "string",
        },
        hour_restday_3: {
          label: "休息日7.5~12",
          type: "string",
        },
        hour_official_holiday_1: {
          label: "國定假日前7.5小時",
          type: "string",
        },
        hour_official_holiday_2: {
          label: "國定假日7.5~9",
          type: "string",
        },
        hour_official_holiday_3: {
          label: "國定假日10~12",
          type: "string",
        },
        amount_weekday: {
          label: "平日加班費",
          type: "string",
        },
        amount_restday: {
          label: "休息日加班費",
          type: "string",
        },
        amount_official_holiday: {
          label: "國定假日加班費",
          type: "string",
        },
        pay_amount: {
          label: "加班費小計",
          type: "string",
        },
      };
    },
    _xc_work_overtime_settlement() {
      return this.$route.query.xc_work_overtime_settlement;
    },
    _fetchUrl() {
      if (this._xc_work_overtime_settlement) {
        return `/xc_work_overtime?xc_work_overtime_settlement=${this._xc_work_overtime_settlement}`;
      } else {
        return `/xc_work_overtime/index/date?start=${this.$route.query.start}&end=${this.$route.query.end}&xc_employee=${this.$route.query.xc_employee}`;
      }
    },
  },
};
</script>

<style>
</style>