<template>
  <WsFlex
    class="ws-crud-title-bar"
    justifyContent="space-between"
  >
    <WsText
      size="20"
      class="mt-6 flex-0"
    >{{$t(label)}}</WsText>
    <WsIcon
      v-if="paperclip"
      @click="$_onClickPaperclip()"
      size="18"
      class="pt-12 ml-12"
      name="icon-ws-outline-help-outline"
    />
    <!-- <WsSpacer></WsSpacer> -->
    <WsFlex>
      <WsBtn
        v-for="(customBtn, customBtnIndex) in customBtns"
        :key="`btn${customBtnIndex}`"
        class="mr-20"
        :to="$_getCustomBtnTo(customBtn)"
        :disabled="customBtn.disabled"
        @click="$_onCustomBtnClick(customBtn)"
      >
        <WsIcon
          v-if="customBtn.icon&&customBtn.icon.left"
          :size="customBtn.icon.size||'24'"
          :name="customBtn.icon.name||'icon-md-menu'"
          class="mr-3"
        />
        {{customBtn.label}}
        <WsIcon
          v-if="customBtn.icon&&customBtn.icon.right"
          :size="customBtn.icon.size||'24'"
          :name="customBtn.icon.name||'icon-md-menu'"
          class="mr-3"
        />
      </WsBtn>
      <WsBtn
        v-if="exportable"
        class="mr-20"
        @click="$_onExport()"
      >
        <WsIcon name="icon-md-file-download" />
        <div class="
          ml-6">{{$t("匯出")}}</div>
      </WsBtn>
      <WsStateSelect
        v-for="
        (dropdownItem,dropdownIndex)
        in
        customDropdownMenu"
        :key="`dropdown${dropdownIndex}`"
        isEmitItem
        class="mr-20"
        :items="dropdownItem.items"
        :value="dropdownItem.value"
        :modelName="dropdownItem.modelName"
        :textKey="dropdownItem.textKey"
        :valueKey="dropdownItem.valueKey"
        :placeholder="dropdownItem.placeholder"
        @input="$_onCustomDropdownMenuClick($event,dropdownItem)"
      >
      </WsStateSelect>
      <WsBtn
        class="mr-20"
        v-if="batchcreatable"
        :to="`/${_urlModelName}/batchcreate`"
      >
        <WsIcon name="icon-ws-outline-add" />
        <div class="
          ml-6">批次新增</div>
      </WsBtn>
      <WsBtn
        class="mr-20"
        v-if="importable"
        outlined
        @click="$_onImport()"
      >
        <WsIcon name="icon-md-publish" />
        <div class="
          ml-6">匯入</div>
      </WsBtn>
      <WsBtn
        class="mr-20"
        v-if="orderable"
        :to="_orderTo"
        :outlined="true"
      >
        <WsIcon name="icon-md-reorder" />
        <div class="ml-6">{{$t('排序')}}</div>
      </WsBtn>
      <template v-if="creatable">
        <WsBtn
          v-if="pageMode&&!dialogCreate"
          :to="_createUrl"
        >
          <WsIcon name="icon-ws-outline-add" />
          <div class="ml-6">{{$t('new')}}</div>
        </WsBtn>
        <WsBtn
          v-else
          @click="$emit('create')"
        >
          <WsIcon name="icon-ws-outline-add" />
          <div class="ml-6">{{$t('new')}}</div>
        </WsBtn>
      </template>
    </WsFlex>
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      loading: {
        export: false,
      },
    };
  },
  computed: {
    _orderUrl() {
      const _orderUrl = this.orderUrl
        ? this.orderUrl
        : `/${this._urlModelName}/order`;
      return _orderUrl;
    },
    _orderTo() {
      let _orderQuery = {};
      if (this.params && JSON.stringify(this.params) != "{}") {
        _orderQuery = this.params;
        _orderQuery.page = 1;
      }
      return { path: this._orderUrl, query: _orderQuery };
    },
    _createUrl() {
      const _createUrl = this.createUrl
        ? this.createUrl
        : `/${this._urlModelName}/create`;
      return _createUrl;
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
  },
  methods: {
    $_getCustomBtnTo(customBtn) {
      if (customBtn.getTo) {
        return customBtn.getTo(this.params);
      } else {
        return customBtn.to;
      }
    },
    $_onClickPaperclip() {
      const _paperclip = this.$config.paperclip?.[this.paperclip];
      if (!_paperclip) {
        return;
      }
      this.$store.dispatch("app/setPaperclip", _paperclip);
    },
    async $_onImport() {
      this.$emit("import");
    },
    async $_onExport() {
      const res = await this.$axios.get(
        `${this.modelName}/export/excel/signedurl`,
        {
          params: this.params,
        }
      );
      window.open(res.data, "_blank");
    },
    $_onCustomBtnClick(btn) {
      if (btn.to) {
        return;
      } else if (btn.emit) {
        this.$emit("customBtnClick", btn.emit);
      } else {
        this.$emit("customBtnClick", btn.event);
      }
    },
    $_onCustomDropdownMenuClick($event, dropdownItem) {
      const item = {
        id: dropdownItem.id,
        value: $event,
      };
      this.$emit("title-custom-dropdown-menu-click", item);
    },
  },
  props: {
    customBtns: {
      type: Array,
      default() {
        return [];
      },
    },
    customDropdownMenu: {
      type: Array,
      default() {
        return [];
      },
    },
    dialogCreate: {
      type: Boolean,
      default: false,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    importable: Boolean,
    createUrl: String,
    modelName: String,
    label: String,
    pageMode: Boolean,
    labelInLocale: {
      type: Boolean,
      default: true,
    },
    urlModelName: String,
    orderable: Boolean,
    orderUrl: String,
    exportable: Boolean,
    params: Object,
    paperclip: String,
    batchcreatable: Boolean,
  },
};
</script>