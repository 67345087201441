<template>
  <tr
    v-if="C_folder"
    @drag="$_onDrag($event)"
    class="file-system-drive-list-folder"
  >
    <td class="pa-8">
      <WsLink
        class="flex-1"
        :to="_to"
        @click="$_onClickFolder()"
        :class="[{'cursor-pointer':_hasClickListener}]"
      >
        <WsFlex>
          <FolderIcon></FolderIcon>
          <WsText
            class="ml-8"
            :color="$color.link"
          >{{ this.C_folder.name }}</WsText>
        </WsFlex>
        <WsSpacer></WsSpacer>
      </WsLink>
    </td>
    <td>
    </td>
    <td>
    </td>
    <td>
      <WsFlex
        justifyContent="flex-end"
        class="file-system-drive-list-folder__actions"
      >
        <WsIconBtn
          class="mx-4"
          tooltip="Edit"
          name="icon-md-edit"
          @click="$_onUpdate()"
        ></WsIconBtn>
        <WsIconBtn
          class="mx-4"
          tooltip="Delete"
          name="icon-md-delete"
          @click="$_onDelete()"
        ></WsIconBtn>
      </WsFlex>
    </td>
  </tr>
</template>

<script>
export default {
  data() {
    return {
      C_folder: null,
    };
  },
  props: {
    folder: {},
    switchMode: {},
    urlPrefix: {},
  },
  methods: {
    $_onClickFolder() {
      if (this.switchMode != "in_component") {
        return;
      }
      this.$emit("switch", this.folder);
    },
    $_onDelete() {
      this.$store.dispatch("app/setAlert", {
        title: "確定刪除資料夾？",
        description: "",
        action: this.$_onSubmitDelete,
      });
    },
    async $_onSubmitDelete() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.delete(`/drive/folder/${this.folder.id}`);
        this.$emit("delete");
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onUpdate() {
      this.$store.dispatch("app/setStateDialog", {
        fields: this._folderFields,
        data: {
          name: this.C_folder.name,
        },
        title: "編輯資料夾",
        onSubmit: this.$_onSumitUpdateFolder,
      });
    },
    async $_onSumitUpdateFolder(state) {
      try {
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$axios.patch(`/drive/folder/${this.folder.id}`, {
          name: state.name,
        });
        this.C_folder = res.data.data;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onDrag($event) {
      console.log("$_onDrag", $event);
    },
  },
  computed: {
    _hasClickListener() {
      return this.switchMode == "in_component";
    },
    _folderFields() {
      return {
        name: this.$store.state.stone_model.folder.fields.name,
      };
    },
    _moreMenuItems() {
      return [
        {
          label: "編輯",
          onClick: () => {
            this.$_onUpdate();
          },
        },
        {
          label: "刪除",
          onClick: () => {
            this.$_onDelete();
          },
        },
      ];
    },
    _to() {
      if (this.switchMode == "route") {
        if (this.urlPrefix) {
          return `${this.urlPrefix}/drive/folder/${this.folder.id}`;
        } else {
          return `/drive/folder/${this.folder.id}`;
        }
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.C_folder = this.folder;
  },
};
</script>

<style lang="scss" scoped>
.file-system-drive-list-folder {
  &:hover {
    background-color: var(--white4d);
    .file-system-drive-list-folder__actions {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .file-system-drive-list-folder__actions {
    opacity: 0;
    pointer-events: none;
  }
}
</style>