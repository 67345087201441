export default {
  $_returnMyBoardComponent() {
    return [
      {
        keepLocation: 'left',
        componentName: 'LeaveDayFunctionBoard',
        sq: 1
      },
      {
        keepLocation: 'left',
        componentName: 'LeaveDayBoard',
        sq: 2
      },
      // {
      //   keepLocation: 'right', 
      //   componentName: 'RenewalApprovalBoard',
      //   sq: 2
      // },
      {
        keepLocation: 'right',
        componentName: 'MyLeaveDayBoard',
        sq: 6
      },
    ]
  }
}
