<template>
  <WsMain class="xs-pa-0">
    <WsCrud
      ref="WsCrud"
      :modelName="$store.state.stone_model.xc_leave_day.modelName"
      label="我的代理請假單"
      :fields="_fields"
      getUrl="/mysubs/xc_leave_day"
      dialogCreateSubmitUrl="/mysubs/xc_leave_day"
      :getReadFetchUrl="$_getReadFetchUrl"
      :customTableActions="customTableActions"
      :showFields="showFields"
      @status-ok="$_onStatusOk($event)"
      @status-no="$_onStatusNo($event)"
      :inRowBtnRead="true"
      :creatable="true"
      :updatable="false"
      :deletable="false"
      @item-cancel="$_onCancel($event)"
      @item-withdraw="$_onWithdraw($event)"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      customTableActions: [
        {
          text: "取消",
          emit: "item-cancel",
          displayCheck(item) {
            return item.status == "approved";
          },
        },
      ],
      showFields: [
        "type",
        "xc_employee",
        "created_at",
        "start_at",
        "end_at",
        "days",
        "hours",
        "is_aboard",
        "status",
      ],
    };
  },
  methods: {
    $_getReadFetchUrl(item) {
      return `/mysubs/xc_leave_day/${item.id}`;
    },
    async $_onCancel($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.mysubs_cancel($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "canceled",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onWithdraw($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.mysubs_withdraw($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "withdrawn",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onStatusOk($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.approve($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "approved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onStatusNo($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.disapprove($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "disapproved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _fields() {
      return {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_leave_day.fields,
          [
            "xc_employee",
            "created_at",
            "type",
            "start_at",
            "end_at",
            "status",
            "days",
            "hours",
            "is_aboard",
            "relate_files",
            "note",
          ]
        ),
      };
    },
  },
};
</script>