<template>
  <WsFlex
    class="flex-full"
    flexDirection="column"
  >
    <WsActionApi
      :fetchUrl="`xc_project_member_month_day`"
      :params="_params"
      @fetch="$_onFetch($event)"
    >
    </WsActionApi>
    <WsMenuView
      class="flex-full"
      :menu="_menu"
    >
      <router-view
        :xc_project="xc_project"
        :key="$route.fullPath"
      ></router-view>
    </WsMenuView>
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      modeldatas: null,
    };
  },
  props: {
    xc_project: {},
  },
  methods: {
    $_onFetch($event) {
      if (
        !this._member_month_day_id &&
        this.$route.name == "member_month_day_index"
      ) {
        if ($event.data.length) {
          this.$router.replace(
            `/xc_project/${this.xc_project.id}/member_month_day/${$event.data[0].id}`
          );
        } else {
          this.$router.replace(
            `/xc_project/${this.xc_project.id}/member_month_day/create`
          );
        }
      }
      this.modeldatas = $event.data;
    },
  },
  computed: {
    _menu() {
      if (!this.modeldatas) {
        return [];
      }
      const _menu = [
        // {
        //   title: "建立新版本",
        //   link: `/xc_project/${this.xc_project.id}/member_month_day/create`,
        //   style: {
        //     "background-color": this.$color.primary,
        //   },
        // },
      ];
      this.modeldatas.forEach((modeldata) => {
        _menu.push({
          title: this.$moment(modeldata.set_at).format("YYYY-MM-DD"),
          link: `/xc_project/${this.xc_project.id}/member_month_day/${modeldata.id}`,
        });
      });
      return _menu;
    },
    _member_month_day_id() {
      return this.$route.params.member_month_day_id;
    },
    _params() {
      return {
        xc_project: this.xc_project.id,
      };
    },
  },
};
</script>

<style>
</style>