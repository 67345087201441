<template>
  <WsFlex>
    <WsMain>
      <WsStateForm
        :fields="_fields"
        :value="value"
        @input="$emit('input', $event)"
      ></WsStateForm>
    </WsMain>
    <div>
      <InfoQuotation v-bind="value"></InfoQuotation>
    </div>
  </WsFlex>
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    _fields() {
      // let keys = [
      //   "name",
      //   "xc_customer",
      //   "creator_name",
      //   "contact_window",
      //   "contact_window_email",
      //   "has_return_sign",
      //   "quote_at",
      //   "expected_start_at",
      //   "has_total_amount",
      //   "execute_items",
      //   "des_items",
      // ];
      // if (this.value && !this.value.xc_customer) {
      //   keys = [
      //     "name",
      //     "xc_customer",
      //     "client_name",
      //     "creator_name",
      //     "contact_window",
      //     "contact_window_email",
      //     "has_return_sign",
      //     "quote_at",
      //     "expected_start_at",
      //     "has_total_amount",
      //     "execute_items",
      //     "des_items",
      //   ];
      // }
      // return this.$o_o.$h.model.getFieldsByKeys(this.$store.state.stone_model.quotation.fields, keys)
      const _fields = { ...this.$store.state.stone_model.quotation.fields };
      // return this.$store.state.stone_model.quotation.fields
      delete _fields.id;
      delete _fields.created_at;
      delete _fields.updated_at;
      delete _fields.no;
      if (this.value && this.value.xc_customer) {
        delete _fields.client_name;
      }
      return _fields;
    },
  },
};
</script>