<template>
  <WsDialog
    ref="WsDialog"
    :type="type"
    :title="title"
    :contentHasPadding="contentHasPadding"
  >
    <template #content>
      <slot name="content"></slot>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onClose()"
        :outlined="true"
      >{{ $t('cancel') }}</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({}),

  computed: {},

  methods: {
    $_onSubmit() {
      this.$emit("submit");
    },
    $_onClose() {
      this.$emit("close");
      this.$refs.WsDialog.close();
    },
    close() {
      this.$refs.WsDialog.close();
    },
    open() {
      this.$refs.WsDialog.open();
    },
  },

  mounted() {},

  props: {
    type: {},
    title: {},
    contentHasPadding: {},
  },
  watch: {},
};
</script>