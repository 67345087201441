<template>
  <div class="info-quotation">
    <div class="info-quotation__title">山葵組設計股份有限公司</div>
    <div class="info-quotation__title">報價單</div>
    <img
      src="https://storage.googleapis.com/ws_garden/general/16988225725wPre/member_bottom_line.png"
      alt=""
      class="info-quotation__title-line"
    >
    <h1>一、聯絡資訊</h1>
    <div class="info-quotation__grid">
      <div class="info-quotation_info">
        <div class="info-quotation_info__title">專案名稱</div>
        <div class="info-quotation_info__content">{{ name }}</div>
      </div>
      <div
        class="info-quotation_info"
        v-if="_client_name"
      >
        <div class="info-quotation_info__title">客戶名稱</div>
        <div class="info-quotation_info__content">{{ _client_name }}</div>
      </div>
      <div class="info-quotation_info">
        <div class="info-quotation_info__title">報價日期</div>
        <div class="info-quotation_info__content">{{
          $moment(quote_at).format('YYYY.MM.DD') }}</div>
      </div>
      <div
        class="info-quotation_info"
        v-if="expected_start_at"
      >
        <div class="info-quotation_info__title">執行日期</div>
        <div class="info-quotation_info__content">{{
          $moment(expected_start_at).format('YYYY.MM.DD') }}
        </div>
      </div>
      <div
        class="info-quotation_info"
        v-if="contact_window"
      >
        <div class="info-quotation_info__title">聯絡代表</div>
        <div class="info-quotation_info__content">{{ contact_window }}</div>
      </div>
      <div class="info-quotation_info">
        <div class="info-quotation_info__title">報價單號</div>
        <div class="info-quotation_info__content">{{ no }}</div>
      </div>
      <div
        class="info-quotation_info"
        v-if="creator_name"
      >
        <div class="info-quotation_info__title">負責人員</div>
        <div class="info-quotation_info__content">{{ creator_name }}</div>
      </div>
      <div
        class="info-quotation_info"
        v-if="contact_window_email"
      >
        <div class="info-quotation_info__title">電子郵件</div>
        <div class="info-quotation_info__content">{{ contact_window_email }}</div>
      </div>
    </div>
    <div v-if="execute_items && execute_items.length">
      <h1>二、報價（新台幣）</h1>
      <div class="quotation-items-section">
        <div class="quotation-items-title-section">
          <div>項目</div>
          <div>工作內容</div>
          <!-- <div>工作天數</div> -->
          <div class="align-right">單價</div>
        </div>
        <div class="quotation-items">
          <div
            class="quotation-item"
            v-for="(excutes_e, excutes_k) in execute_items"
            :key="excutes_k"
          >
            <div>{{ excutes_e.name }}</div>
            <div>{{ excutes_e.content }}</div>
            <!-- <div>{{ excutes_e.excute_days }}</div> -->
            <div class="right">{{ $_getCurrencyFormat(excutes_e.price) }}</div>
          </div>
        </div>
        <div
          v-if="has_total_amount"
          class="quotation-calcs mt-12"
        >
          <div class="quotation-calc">
            <div>小計</div>
            <div>{{ $_getCurrencyFormat($_getExecuteItemsCalc(execute_items)) }}
            </div>
          </div>
          <div class="quotation-calc">
            <div>營業稅</div>
            <div>{{ $_getCurrencyFormat($_getExecuteItemsCalc(execute_items) *
              0.05)
            }}</div>
          </div>
          <div class="quotation-calc">
            <div>總計</div>
            <div>{{ $_getCurrencyFormat($_getExecuteItemsCalc(execute_items) *
              1.05)
            }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="des_items && des_items.length">
      <h1>三、其他說明</h1>
      <ul class="remarks-section">
        <li
          v-for="(des_items_e, descriptions_index) in des_items"
          :key="descriptions_index"
        >{{ des_items_e.content }}</li>
      </ul>
    </div>
    <div v-if="has_return_sign">
      <h1>四、客戶回簽欄</h1>
      <div class="signature">
        <div class="line"></div>
        <p>（簽章）</p>
      </div>
    </div>
    <div class="footer">
      <div class="logo">
        <img
          src="https://storage.googleapis.com/ws_garden/general/1698822499hOjey/logo_green.png"
          alt=""
        >
      </div>
      <div class="info-sec">
        <div class="quotation-info">
          <div class="info-title">統&nbsp;&nbsp;編</div>
          <div class="info-content">52243963</div>
        </div>
        <div class="quotation-info">
          <div class="info-title">電&nbsp;&nbsp;話</div>
          <div class="info-content">02-2928-0133</div>
        </div>
      </div>
      <div class="info-sec">
        <div class="quotation-info">
          <div class="info-title">Email</div>
          <div class="info-content">hello@wasateam.com</div>
        </div>
        <div class="quotation-info">
          <div class="info-title">地&nbsp;&nbsp;址</div>
          <div class="info-content">104070 台北市中山區松江路 315 號 2 樓 D3 室</div>
        </div>
      </div>
    </div>
    <img
      src="https://storage.googleapis.com/ws_garden/general/1698822513yBJmr/wasajon-2.png"
      alt=""
      class="wasajon"
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    $_getExecuteItemsCalc(list) {
      let total = 0;
      list.forEach((list_e) => {
        total += Math.round(list_e.price);
      });
      return total;
    },
    $_getCurrencyFormat(price) {
      return this.$numeral(price).format("$ 0,0");
    },
  },
  computed: {
    _client_name() {
      if (this.xc_customer) {
        return this.xc_customer.name;
      } else {
        return this.client_name;
      }
    },
  },
  props: {
    name: {},
    client_name: {},
    quote_at: {},
    expected_start_at: {},
    contact_window: {},
    creator_name: {},
    contact_window_email: {},
    has_total_amount: {},
    execute_items: {},
    des_items: {},
    has_return_sign: {},
    xc_customer: {},
    no: {},
  },
};
</script>

<style lang="scss" scoped>
.info-quotation {
  position: relative;
  overflow: hidden;
  padding: 50px 95px;
  margin: 0 auto;
  width: 796px;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);

  .info-quotation__title {
    padding: 6px;
    font-size: 1.25rem;
    color: var(--primary1l);
    letter-spacing: 4px;
    width: 100%;
    text-align: center;
  }

  .info-quotation__title-line {
    width: 100%;
  }

  h1 {
    color: var(--black5l);
    font-size: 1rem;
    padding-top: 40px;
    padding-bottom: 16px;
    border: 0;
  }

  .info-quotation__grid {
    width: 100%;
    // border-top: solid 4px var(--primary);
    // border-bottom: solid 4px var(--primary);
    padding: 27px 20px;
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 10px;
    grid-row-gap: 18px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: var(--primary4l);
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: var(--primary4l);
    }

    .info-quotation_info {
      display: flex;

      .info-quotation_info__title {
        flex: 0 0 auto;
        white-space: nowrap;
        font-size: 0.875rem;
        color: var(--black4l);
        padding-right: 12px;
        // border-right: solid 1px var(--black4l);
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          right: 0;
          background-color: var(--black4l);
        }
      }

      .info-quotation_info__content {
        font-size: 0.875rem;
        color: var(--black2l);
        padding-left: 12px;
      }
    }
  }

  .quotation-items-section {
    .quotation-items-title-section {
      display: grid;
      width: 100%;
      grid-template-columns: 150px 1fr 200px;

      &.people {
        grid-template-columns: 150px 1fr 200px;
      }

      div {
        background-color: var(--primary3l);
        padding: 9px 8px;
        color: #fff;
        font-size: 0.875rem;

        &.align-right {
          text-align: right;
        }
      }
    }

    .quotation-items {
      .quotation-item {
        display: grid;
        width: 100%;
        grid-template-columns: 150px 1fr 200px;
        position: relative;
        // border-bottom: solid 2px var(--primary1l);
        padding-bottom: 2px;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: var(--primary1l);
        }

        &.people {
          grid-template-columns: 150px 1fr 200px;
        }

        div {
          padding: 12px 8px;
          font-size: 0.875rem;
          color: var(--black1l);
          white-space: pre-wrap;

          &.right {
            text-align: right;
          }
        }
      }
    }

    .quotation-calcs {
      .quotation-calc {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 100px;

        div {
          padding: 6px 8px;
          font-size: 0.875rem;
          color: var(--primary1l);
          text-align: right;
        }
      }
    }
  }

  .remarks-section {
    // @include auto_margin_top(20px);
    list-style-type: decimal;
    padding-left: 30px;
    padding-top: 8px;
    padding-bottom: 8px;

    li {
      padding: 4px 8px;
      font-size: 0.875rem;
      color: var(--black1l);
      white-space: pre-wrap;
    }
  }

  .signature {
    margin-top: 32px;
    // @include auto_margin_top(30px);
    // @include flex;
    display: flex;

    .line {
      width: 300px;
      border-bottom: solid 1px var(--gray1l);
    }

    p {
      padding: 4px 8px;
      font-size: 0.875rem;
      color: var(--black1l);
    }
  }

  .footer {
    display: flex;
    margin-top: 60px;
    // @include auto_margin_top(60px);
    // @include flex;
    width: 100%;

    .logo {
      display: flex;
      padding-top: 16px;
      align-items: flex-start;
      justify-content: flex-start;
      width: 150px;
      flex: 0 0 auto;

      img {
        width: 120px;
      }
    }

    .info-sec {
      width: 100%;
      padding: 10px 16px 10px 18px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--primary1l);
      }

      .quotation-info {
        margin-top: 12px;
        margin-bottom: 12px;
        display: flex;

        .info-title {
          width: 45px;
          flex: 0 0 auto;
          font-size: 0.75rem;
          color: var(--gray1l);
        }

        .info-content {
          width: 100%;
          font-size: 0.75rem;
          color: var(--black1l);
          letter-spacing: 1px;
        }
      }
    }
  }

  .wasajon {
    position: absolute;
    bottom: -20px;
    right: -15px;
    width: 120px;
    z-index: 1;
  }
}
</style>