<template>
  <div class="ws-state-spec-setting mt-14">
    <WsCard
      v-for="(item, index) in spec_settings"
      :key="index"
      class="ws-state-spec-card mb-16"
      color="#333"
    >
      <WsIcon
        name="icon-md-close"
        class="ws-state-spec-card-icon-close"
        size="24"
        @click="$_deleteSpecSetting(index)"
      />
      <WsState
        type="text"
        label="商品規格名稱"
        v-model="item.name"
        rules="required"
        class="pb-24 mt-0"
        style="border-bottom: 1px solid var(--gray6l, #D6D5E4);"
      />
      <WsStateSort
        v-if="item.spec_setting_items.length"
        v-model="item.spec_setting_items"
        class="mt-20"
        :key="item.spec_setting_items.length"
        :useDragHandle="true"
      >
        <template #slick-item="{item, itemIndex}">
          <WsFlex alignItems="center" gap="8px">
            <WsState
              v-if="index == 0"
              type="image"
              v-model="item.image"
              :previewMaxWidth=150
            />
            <WsState
              type="text"
              v-model="item.name"
              placeholder="請輸入規格"
              class="width-full mt-0"
            />
            <WsIcon v-handle name="icon-ws-outline-drag-vertical" size="24" class="cursor-move" />
            <WsIcon name="icon-sc-liff-delete" size="24" @click.stop="$_deleteSpecSettingsItem(index, itemIndex)" />
          </WsFlex>
        </template>
      </WsStateSort>
      <WsBtn @click="$_addSettingsItem(index)" outlined class="mt-10">
        <WsIcon name="icon-ws-outline-add" class="mr-4" />新增
      </WsBtn>
    </WsCard>
    <WsBtn @click="$_createSpecSetting()" v-if="createSpecSettings">
      <WsIcon name="icon-ws-outline-add" class="mr-4" />{{ _btnText }}
    </WsBtn>
    <WsStateSpecTable
      ref="WsStateSpecTable"
      :spec_settings="spec_settings"
      @input="$_formInput"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      spec_settings: [],
    }
  },
  mounted() {
    if (this.value) {
      this.spec_settings = this.value
    }
    if (this.stateData?.[this.specFieldName]) {
      this.$refs.WsStateSpecTable.$_init(this.stateData[this.specFieldName])
    }
  },
  methods: {
    $_createSpecSetting() {
      this.spec_settings.push({
        name: '',
        spec_setting_items: [
          {
            name: '',
            ...(this.spec_settings.length === 0 && { image: '' })
          }
        ]
      })
      this.$refs.WsStateSpecTable.$_addSpec()
    },
    $_addSettingsItem(spec_settings_index) {
      this.spec_settings[spec_settings_index].spec_setting_items.push({
        name: '',
        ...(spec_settings_index === 0 && { image: '' })
      })
    },
    $_deleteSpecSetting(spec_settings_index) {
      if (this.spec_settings[spec_settings_index]) {
        this.spec_settings.splice(spec_settings_index, 1)
      }
    },
    $_deleteSpecSettingsItem(spec_settings_index, spec_setting_item_index) {
      if (this.spec_settings[spec_settings_index]?.spec_setting_items[spec_setting_item_index]) {
        this.spec_settings[spec_settings_index].spec_setting_items.splice(spec_setting_item_index, 1)
      }
      this.$refs.WsStateSpecTable.$_deleteSpec(spec_setting_item_index)
    },
    $_formInput($event) {
      this.$emit('form-input', {
        ...this.stateData,
        [this.specFieldName]: $event
      })
    }
  },
  computed: {
    _btnText() {
      if (this.spec_settings?.length > 0) {
        return `新增商品規格${this.spec_settings.length + 1}`
      } else {
        return `新增商品規格`
      }
    }
  },
  watch: {
    spec_settings(newVal) {
      this.$emit('input', newVal)
    }
  },
  props: {
    stateData: Object,
    value: Array,
    specFieldName: String,
    createSpecSettings: {
      type: [Boolean, Number],
    },
  }
}
</script>
