<template>
  <div class="ws-menu-group">
    <div
      v-for="(menuItem,menuKey) in items"
      :key="menuKey"
    >
      <WsMenu
        v-if="menuItem.menu"
        v-bind="menuItem"
      ></WsMenu>
      <WsMenuItem
        v-else
        v-bind="menuItem"
      ></WsMenuItem>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {},
  },
};
</script>

<style>
</style>