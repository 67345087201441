<template>
  <WsMain>
    <ValidationObserver ref="form">
      <WsStateForm
        :fields="fields"
        v-model="state"
      ></WsStateForm>
    </ValidationObserver>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        title: {
          label: "Title",
        },
        body: {
          label: "Body",
        },
        image: {
          label: "Image",
          type: "image",
        },
        data: {
          label: "Data (Json Format)",
          type: "textarea",
        },
        tokens: {
          label: "Tokens",
          type: "tags",
        },
      },
      state: {},
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.testing.fcm(this.state);
        this.$store.dispatch("app/addSnack", "Fcm Sent.");
      } catch (error) {
        console.error("error", error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>

<style>
</style>