const model = {
  options: {
    // id: true,
    // timestamp: true,
    // tags: true,
  },
  modelName: "xc_project_member_month_day",
  label: "計畫人月",
  fields: {
    set_at: {
      type: 'date',
      label: '設定日期',
      rules: 'required',
      // col: 3,
    },
    remark: {
      type: 'textarea',
      label: '備註',
    }
  },
}

export default model;