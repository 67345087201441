<template>
  <div class="width-full">
    <WsActionApi
      :fetchUrl="`xc_project_member_month_day/${_member_month_day_id}`"
      @fetch="$_onFetch($event)"
    >
    </WsActionApi>
    <XcProjectMemberMonthDayReadTable
      class="mt-20"
      v-if="modeldata"
      ref="XcProjectMemberMonthDayReadTable"
      :items="modeldata.member_month_days"
      :start_at="xc_project.start_at"
      :end_at="xc_project.end_at"
      :exportFilename="`計畫人月-${$moment(modeldata.set_at).format('YYYY-MM-DD')}`"
      :exportable="true"
      :importable="true"
    />
    <WsStateForm
      class="mt-20"
      v-model="state"
      :fields="$store.state.stone_model.xc_project_member_month_day.fields"
    ></WsStateForm>
    <WsBtn
      class="mt-20"
      @click="$_onSubmit()"
    >{{$t('送出')}}</WsBtn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        const _state = this.$refs.XcProjectMemberMonthDayReadTable.getState();
        const _postdata = {
          ...this.state,
          member_month_days: _state,
        };
        await this.$axios.patch(
          `xc_project_member_month_day/${this._member_month_day_id}`,
          _postdata
        );
        this.$store.dispatch("app/addSnack", this.$t("送出完成"));
        this.$router.push(
          `/xc_project/${this.xc_project.id}/member_month_day/${this._member_month_day_id}`
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onExport() {
      this.$refs.XcProjectMemberMonthDayReadTable.exportExcel();
    },
    $_onFetch($event) {
      this.modeldata = $event.data;
      this.state = {
        ...$event.data,
      };
    },
  },
  props: {
    xc_project: {},
  },
  computed: {
    _member_month_day_id() {
      return this.$route.params.member_month_day_id;
    },
  },
};
</script>

<style>
</style>