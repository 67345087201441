<template>
  <WsMain>
    <WsActionApi
      fetchUrl="my/overview/xc_leave_day"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching" />
      <XcLeaveDayOverview
        v-else
        :overview="res"
      />
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {
  methods: {},
};
</script>
