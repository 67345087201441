<template>
  <WsMain>
    <div v-if="multiple">
      <div v-if="files && files.length">
        <WsFlex
          v-for="(file, fileIndex) in files"
          :key="fileIndex"
          :gap="10"
          class="ws-file-state-image"
        >
          <WsText class="mb-20">{{ file.name }}</WsText>
          <WsIconBtn
            @click="$_onClear(fileIndex)"
            backgroundColor="#000"
            name="icon-md-delete"
          />
        </WsFlex>
      </div>
    </div>
    <div v-else>
      <WsImg
        v-if="url"
        :url="url"
        alt=""
        @click="$_onUploadClick()"
        :wireBackground="true"
      />
    </div>
    <div>
      <WsBtn @click="$_onUploadClick()">選擇檔案</WsBtn>
    </div>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="*/*"
      :multiple="multiple"
    >
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    file: null,
    url: null,
    files: [],
    urls: [],
  }),

  methods: {
    $_onClear(urlIndex) {
      let filesArray = Array.from(this.files);
      filesArray.splice(urlIndex, 1)
      this.files = filesArray
    },
    async $_onChange($event) {
      if (this.multiple) {
        this.$_checkFilesSize($event.target.files);
        this.files = $event.target.files;
      } else {
        this.$_checkFilesSize($event.target.files[0]);
        this.file = $event.target.files[0];
        this.url = await this.$o_o.$h.file.getBase64FromFile(this.file);
      }
    },
    $_checkFilesSize(files) {
      for (let i = 0; i < files.length; i++) {
        this.$_checkFileSize(files[i]);
      }
    },
    $_checkFileSize(file) {
      if (this.maxSize && file.size > this.maxSize) {
        alert(
          `File Size over ${this.$o_o.$h.file.getFileSizeText(this.maxSize)}`
        );
        throw new Error(
          `File Size over ${this.$o_o.$h.file.getFileSizeText(this.maxSize)}`
        );
      }
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    submit() {
      return new Promise((resolve, reject) => {
        if (this.multiple) {
          const tasks = [];
          for (let i = 0; i < this.files.length; i++) {
            tasks.push(
              this.$o_o.$s._m.file_system_file.upload(
                this.files[i],
                this.storageTarget
              )
            );
          }
          Promise.all(tasks)
            .then((res) => {
              console.log("res", res);
              this.$emit("submit", res);
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          this.$o_o.$s._m.file_system_file
            .upload(this.file, this.storageTarget)
            .then((res) => {
              this.$emit("submit", res);
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
  },

  props: {
    signed: {
      type: Boolean,
      default: false,
    },
    storageTarget: {},
    multiple: {},
    maxSize: {
      default: 5 * 1024 * 1024,
    },
  },
};
</script>

<style></style>