<template>
  <div class="ws-file-state-images">
    <div
      class="ws-flex-wrap"
      v-if="value && value.length"
    >
      <div
        v-for="(valueItem, valueItemIndex) in value"
        :key="valueItemIndex"
        class="ws-file-state-image"
      >
        <img
          v-lazy="valueItem"
          alt=""
          class="mb-10 ws-img wire-bg"
          :style="`max-width:150px;max-height:150px`"
        />
        <WsIconBtn
          @click="$_onClear(valueItemIndex)"
          class="remove-btn"
          backgroundColor="#000"
          name="icon-md-delete"
        />
        <WsText
          class="ws-file-state-image__name"
          v-tooltip.top="$o_o.$h.file.getFileNameByUrl(valueItem)"
        >{{ $o_o.$h.file.getFileNameByUrl(valueItem) }}</WsText>
      </div>
    </div>
    <FileSystemFilePickerImage
      :signed="signed"
      :storageTarget="storageTarget"
      ref="FileSystemFilePickerImage"
      @submit="$_onSubmit($event)"
      multiple
    >
    </FileSystemFilePickerImage>
    <WsBtn @click="$_onPickDialogOpen()">{{ $t('pick_image') }}</WsBtn>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    $_onSubmit($event) {
      const _value = this.value ? [...this.value] : [];
      this.$emit("input", [..._value, ...$event]);
    },
    $_onClear(valueIndex) {
      const _value = [...this.value];
      _value.splice(valueIndex, 1);
      this.$emit("input", _value);
    },
    $_onPickDialogOpen() {
      this.$refs.FileSystemFilePickerImage.open();
    },
  },

  props: {
    value: {},
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
    storageTarget: {},
    maxSize: {
      default: 5 * 1024 * 1024,
    },
  },
};
</script>

<style></style>