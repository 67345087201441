const model = {
  fields: {
    xc_projects: {
      type: 'belongsToMany',
      label: '計畫',
      textKey: "name",
      modelName: "xc_project",
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_projects'
      ]
    },
    index_my: {
      // showFields: [
      //   'xc_projects'
      // ]
    },
  }
}
export default model