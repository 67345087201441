<template>
  <div
    class="ws-more-menu-btn"
    v-click-outside="$_onClickOutside"
  >
    <WsIcon
      size="24"
      name="icon-md-more-vert"
      @click="$_onClick($event)"
      ref="moreButton"
    ></WsIcon>
    <div
      v-if="isMenuVisible"
      class="ws-more-menu-btn__menu"
      :style="{ top: menuPosition.top + 'px', right: menuPosition.right + 'px' }"
      ref="moreMenu"
    >
      <ul>
        <li
          v-for="(item,itemIndex) in items"
          :key="itemIndex"
          @click="$_selectOption(item)"
        >
          <WsText>{{ item.label }}</WsText>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMenuVisible: false,
      menuPosition: { top: 0, right: 0 },
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    $_onClick($event) {
      this.toggleMenu($event);
    },
    toggleMenu($event) {
      this.isMenuVisible = !this.isMenuVisible;

      if (this.isMenuVisible) {
        setTimeout(() => {
          this.setMenuPosition($event);
        }, 0);
      } else {
        this.menuPosition = { top: 0, right: 0 };
      }
    },
    setMenuPosition() {
      const buttonRect = this.$refs.moreButton.$el.getBoundingClientRect();
      const menuRect = this.$refs.moreMenu.getBoundingClientRect();

      let top = buttonRect.height;
      if (buttonRect.top + menuRect.height > window.innerHeight) {
        console.log("1111");
        top = -1 * menuRect.height;
      }

      let right = buttonRect.right - buttonRect.x + buttonRect.width;
      if (menuRect.right > window.innerWidth) {
        right = buttonRect.right - menuRect.width;
      }

      this.menuPosition = {
        top: top,
        right: right,
      };
    },
    $_selectOption(option) {
      this.isMenuVisible = false;
      if (option.onClick) {
        option.onClick();
      }
    },
    $_onClickOutside() {
      this.isMenuVisible = false;
    },
  },
};
</script>

<style scoped>
.ws-more-menu-btn {
  position: relative;
  display: inline-block;
}
.ws-more-menu-btn__menu {
  position: absolute;
  width: 120px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 10;
  overflow: hidden;
}
.ws-more-menu-btn__menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.ws-more-menu-btn__menu li {
  padding: 8px 12px;
  cursor: pointer;
}
.ws-more-menu-btn__menu li:hover {
  background-color: #f0f0f0;
}
</style>