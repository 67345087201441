const model = {
  fields: {
    testing_cases: {
      type: 'belongsToMany',
      label: 'Testing Case',
      modelName: 'testing_case',
      getExtendParamsFromValue: (value) => {
        if (value.xc_project) {
          return {
            xc_project: value.xc_project.id
          }
        } else {
          return {
            xc_project: 0
          }
        }
      }
    },
    testing_records: {
      type: 'hasMany',
      label: 'Testing Record',
      modelName: 'testing_record'
    }
  },
  crudSetting: {
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'testing_cases',
              ],
            },
          ],
        },
      ],
    }
  }
}
export default model