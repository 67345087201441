// import config from '@/__config'

const model = {
  options: {
    // id: true,
    timestamp: true,
  },
  modelName: "xc_work_overtime",
  label: "加班單",
  fields: {
    // id: {
    //   type: "text",
    //   label: 'ID',
    //   readonly: true
    // },
    start_at: {
      type: 'datetime',
      label: '加班開始時間',
      minuteStep: 30,
      format: 'YYYY-MM-DD HH:mm',
      editable: false,
      col: 3,
    },
    end_at: {
      type: 'datetime',
      label: '加班結束時間',
      minuteStep: 30,
      format: 'YYYY-MM-DD HH:mm',
      editable: false,
      col: 3,
      setPreDes(controller) {
        if (
          controller.stateData &&
          controller.stateData.start_at &&
          controller.stateData.end_at
        ) {
          const _diff = controller.$moment(controller.stateData.end_at).diff(controller.$moment(controller.stateData.start_at))
          const hoursDifference = _diff / (1000 * 60 * 60);
          if (hoursDifference) {
            controller.preDes = `加班時數：${hoursDifference}小時`
            return
          }
        }
        controller.preDes = ''
      },
    },
    hour: {
      type: "number",
      label: "加班時數",
      readonly: true,
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
        {
          text: '結算中',
          value: 'settling'
        },
        {
          text: '已支付',
          value: 'payed'
        },
        {
          text: '收回',
          value: 'withdrawn'
        },
        {
          text: '取消',
          value: 'canceled'
        },
        {
          text: '已補休完畢',
          value: 'compensatory_leaved'
        },
      ]
    },
    exchange_for_compensatory_leave: {
      type: 'radio',
      label: '是否換補休',
      defaultValue: 0,
      hint: '補休期限為加班日發生一年內',
      items: [
        {
          label: '否',
          value: 0,
        },
        {
          label: '是',
          value: 1,
        },
      ],
      col: 3,
      setPreDes(controller) {
        const _preDesApiUrl = `/my/xc_leave_day/annual_leave_from_work_overtime/remain_days_and_hours_days`
        controller.$axios.get(_preDesApiUrl).then(res => {
          if (res.data.need_count_remain) {
            controller.preDes = `剩餘 ${res.data.remain_days_and_hours_days}天 ${res.data.remain_days_and_hours_hours}小時`
          } else {
            controller.preDes = ''
          }
        })
      },
    },
    xc_employee: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: 'xc_employee'
    },
    // admin: {
    //   type: "belongsTo",
    //   label: "CMS使用者",
    //   textKey: "name",
    // modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    //   readonly: true,
    // },
    // status_updated_at: {
    //   type: "datetime",
    //   label: "狀態更新時間",
    //   readonly: true,
    // },
    work_overtime_type: {
      type: 'select',
      label: '加班類型',
      readonly: true,
      items: [
        {
          text: '平日加班',
          value: 'weekday'
        },
        {
          text: '休息日加班',
          value: 'restday'
        },
        {
          text: '國定假日加班',
          value: 'official_holiday'
        }
      ]
    },
    pay_amount: {
      type: 'number',
      label: '加班費小計',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "xc_employee",
        // "created_at",
        "start_at",
        "end_at",
        // "hour",
        "status",
        "work_overtime_type",
      ],
      pageMode: false,
      creatable: false,
      updatable: false,
      showFieldsUpdate: [
        "status",
      ]
    },
    index_my: {
      showFields: [
        // "created_at",
        "start_at",
        "end_at",
        "hour",
        "status",
        "work_overtime_type",
      ],
      pageMode: false,
      updatable: false,
      dialogRead: true,
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'start_at',
                'end_at',
                // 'hour',
                'exchange_for_compensatory_leave',
                'remark',
              ],
            },
          ],
        },
      ],
    },
    read: {

    }
  }
}

export default model