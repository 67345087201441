<template>
  <LayoutModelRead
    :id="_testing_case_id"
    v-bind="this.$store.state.stone_model.testing_case"
  ></LayoutModelRead>
</template>

<script>
export default {
  computed: {
    _testing_case_id() {
      return this.$route.params.testing_case_id;
    },
  },
};
</script>

<style>
</style>