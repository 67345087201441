<template>
  <div>
    <TestingCaseCollapse
      class="my-4"
      v-for="(testing_case, testing_caseIndex) in testing_cases"
      :key="testing_caseIndex"
      :testing_case="testing_case"
    ></TestingCaseCollapse>
  </div>
</template>

<script>
export default {
  props: {
    testing_cases: Array,
    testing_records: Array,
  },
};
</script>

<style>
</style>