<template>
  <WsModelMySimpleIndex
    ref="WsModelMySimpleIndex"
    fetchUrl="my/xc_employee_hire/index/managewaitingnextaction"
    modelName="xc_employee_hire"
    label="等待聘用狀態異動"
    :showFields="showFields"
    :readable="false"
    :customTableActions="customTableActions"
  />
</template>

<script>
export default {
  data() {
    return {
      showFields: ["name", "no", "xc_employee_status"],
      customTableActions: [
        {
          text: "續",
          tooltip: "續聘",
          getUrl: (item) => {
            return `/xc_employee_hire_change/create?xc_employee=${item.xc_employee.id}&type=renew`;
          },
        },
        {
          text: "停",
          tooltip: "停聘",
          getUrl: (item) => {
            return `/xc_employee_hire_change/create?xc_employee=${item.xc_employee.id}&type=stop`;
          },
        },
        {
          text: "改",
          tooltip: "改聘",
          getUrl: (item) => {
            return `/xc_employee_hire_change/create?xc_employee=${item.xc_employee.id}&type=change`;
          },
        },
      ],
    };
  },
};
</script>