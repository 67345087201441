<template>
  <div>
    <WsMain>
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="updateForm"
          @submit.prevent
        >
          <WsStateForm
            v-model="state"
            :fields="fields"
          />
        </form>
      </ValidationObserver>
      <WsBtn
        class="mt-24"
        @click="$_onSubmit()"
      >{{ $t('submit') }}</WsBtn>
    </WsMain>
    <!-- <WsBottomNav>
      <template v-slot:rightActions>
      </template>
    </WsBottomNav> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      fields: {
        start: {
          type: "date",
          label: "篩選起始日期",
          rules: "required",
        },
        end: {
          type: "date",
          label: "篩選結束日期",
          rules: "required",
        },
        xc_employee: {
          type: "belongsTo",
          label: "員工",
          modelName: "xc_employee",
          rules: "required",
        },
      },
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$router.push(this._to);
    },
  },
  computed: {
    _to() {
      return `/print/xc_work_overtime?start=${this.state.start}&end=${this.state.end}&xc_employee=${this.state.xc_employee?.id}&name=${this.state.xc_employee?.name}`;
    },
  },
};
</script>

<style>
</style>