<template>
  <WsMain
    v-if="$store.state.app.paperclip"
    class="ws-app-paperclip"
  >
    <WsFlex>
      <WsText class="flex-0">{{$store.state.app.paperclip.title}}</WsText>
      <WsSpacer></WsSpacer>
      <WsIcon
        class="pa-8"
        @click="$store.dispatch('app/setPaperclip', null)"
        name="icon-md-close"
      />
    </WsFlex>
    <WsInfoHtml :value="$store.state.app.paperclip.content"></WsInfoHtml>
  </WsMain>
</template>

<script>
export default {};
</script>

<style lang="scss">
.ws-app-paperclip {
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  width: 300px;
  height: calc(100% - 50px);
  right: 0;
  z-index: 2;
  border-left: 0.5px solid var(--gray5l);
}
</style>