const model = {
  fields: {
    // quotations: {
    //   type: 'hasMany',
    //   label: '報價單',
    //   textKey: "name",
    //   modelName: "quotation",
    //   relationPopup: true,
    //   showFields: [
    //   ]
    // }
  },
  crudSetting: {
    index: {
      showFields: [
      ]
    },
    read: {
      rightFields: [
        // "quotations",
      ],
    },
  }
}
export default model