import H_Router from '@/__stone/helpers/router'

export default [
  {
    path: '/testing/mail',
    name: 'testing_mail',
    component: () => import('@/__modules/testing/views/Testing/Mail.vue')
  },
  {
    path: '/testing/fcm',
    name: 'testing_fcm',
    component: () => import('@/__modules/testing/views/Testing/Fcm.vue')
  },
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'read', 'create', 'update'],
    modelName: 'testing_case_template',
    meta: {
      middleware: 'auth',
      requiredScopes: ['testing-admin'],
    },
  }),
]