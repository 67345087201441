import config from '@/__config';

const model = {
  modelName: "xc_substitute",
  label: "",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    admin: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : "admin",
    },
    substitute_admin: {
      type: "belongsTo",
      label: "代理人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : "admin",
    },
    start_at: {
      type: "datetime",
      label: "開始時間"
    },
    end_at: {
      type: "datetime",
      label: "結束時間"
    },
    end_note: {
      type: 'editor',
      label: '代理結束說明'
    },
    note: {
      type: 'editor',
      label: '說明'
    },
  },
  crudSetting: {
    index: {
      pageMode: false,
      showFields: [
        'admin',
        'substitute_admin',
        'start_at',
        'end_at',
      ],
    },
    read: {
      titleKey: 'name',
      leftFields: [
        'end_note',
        'note',
      ],
      rightFields: [
        'admin',
        'substitute_admin',
        'start_at',
        'end_at',
      ],
    },
    index_my: {
      pageMode: false,
      showFields: [
        'substitute_admin',
        'start_at',
        'end_at',
      ],
    },
    index_my_substitute: {
      creatable: false,
      pageMode: false,
      showFields: [
        'admin',
        'start_at',
        'end_at',
      ],
    },
  }
}

export default model