
const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_performance_bonus',
  label: '員工績效獎金',
  fields: {
    xc_employee: {
      type: 'belongsTo',
      label: '員工',
      modelName: 'xc_employee',
    },
    year: {
      type: 'number',
      label: '年度',
    },
    amount: {
      type: 'currency',
      label: '金額',
    },
    xc_employee_no: {
      type: 'custom',
      label: '員工編號',
      getValue: function (data) {
        return data?.xc_employee?.no
      },
    },
    xc_employee_name: {
      type: 'custom',
      label: '員工姓名',
      getValue: function (data) {
        return data?.xc_employee?.name
      },
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "xc_employee",
        "year",
        "amount",
      ],
      creatable: false,
      inRowBtnRead: false,
      pageMode: true,
      batchcreatable: true
    },
    batch: {
      exportable: true,
      importable: true,
      batchFields: [
        "xc_employee_no",
        "xc_employee_name",
        "year",
        "amount",
      ],
    }
  }
}

export default model