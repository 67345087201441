<template>
  <WsIconBtn
    v-bind="$props"
    name="icon-md-delete"
    @click="$emit('click')"
  ></WsIconBtn>
</template>

<script>
export default {};
</script>

<style>
</style>