<template>
  <WsCard class="files-list-board">
    <WsActionApi
      fetchUrl="/drive/file"
      :params="_params"
      @fetch="$_onFetchFolder($event)"
    >
    </WsActionApi>
    <WsText size="16">常用檔案</WsText>
    <WsFlex
      flexDirection="column"
      class="files-list-board-main-content py-16"
    >
      <WsFlex
        v-for="(file, index) in files"
        :key="index"
        alignItems="center"
        justifyContent="space-between"
        class="files-list-board-file-row py-4"
      >
        <WsText size="14">{{ file.file_name }}</WsText>
        <WsIconBtn
          name="icon-md-file-download"
          size="38"
          @click="$_onFileDownload(file.public_url)"
        />
      </WsFlex>
    </WsFlex>
    <WsLink
      to="/drive/folder/general"
      class="py-8"
    >
      <WsFlex
        justifyContent="flex-end"
        alignItems="center"
        gap="8px"
      >
        <WsText
          size="14"
          :color="$color.gray2d"
        >查看所有檔案</WsText>
        <WsIcon
          name="icon-ws-outline-arrow-right"
          :color="$color.gray2d"
        />
      </WsFlex>
    </WsLink>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      files: [],
    };
  },
  methods: {
    $_onFetchFolder($event) {
      this.files = $event.data;
    },
    $_onFileDownload(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    _params() {
      return {
        limit: 5,
        folder: "general",
      };
    },
  },
};
</script>

<style lang="scss">
.files-list-board {
  .files-list-board-main-content {
    .files-list-board-file-row {
      border-bottom: 1px solid var(--gray5d);
      &:last-child {
        border-bottom: unset;
      }
    }
  }
}
</style>