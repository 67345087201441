<template>
  <WsModelMySimpleIndex
    ref="WsModelMySimpleIndex"
    label="我的請假單"
    modelName="xc_leave_day"
    :filterTabs="filterTabs"
    :showFields="showFields"
    :readDialogFields="readDialogFields"
    :customTableActions="customTableActions"
    @item-cancel="$_onCancel($event)"
    @item-withdraw="$_onWithdraw($event)"
  >
    <template v-slot:WsReadDialogAfterContent="{ item }">
      <XcProcessEventCommentEasyTable
        v-if="item.xc_process_event"
        :id="item.xc_process_event.id"
      />
    </template>
  </WsModelMySimpleIndex>
</template>

<script>
export default {
  data() {
    return {
      customTableActions: [
        {
          text: "取消",
          emit: "item-cancel",
          displayCheck(item) {
            return item.status == "approved";
          },
        },
      ],
      filterTabs: [
        {
          text: "簽核中",
          value: 1,
          params: {
            status: "processing",
          },
        },
        {
          text: "已核准",
          value: 2,
          params: {
            status: "approved",
          },
        },
        {
          text: "不同意",
          value: 3,
          params: {
            status: "disapproved",
          },
        },
        {
          text: "收回",
          value: 4,
          params: {
            status: "withdrawn",
          },
        },
        {
          text: "取消",
          value: 5,
          params: {
            status: "canceled",
          },
        },
      ],
      showFields: ["start_date", "status"],
      readDialogFields: [
        "xc_employee",
        "created_at",
        "type",
        "status",
        "start_date",
        "days",
        "start_at",
        "hours",
        "note",
      ],
    };
  },
  methods: {
    async $_onCancel($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.my_cancel($event.item.id);
        this.$refs.WsModelMySimpleIndex.$_fetchModelData();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onWithdraw($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.my_withdraw($event.item.id);
        this.$refs.WsModelMySimpleIndex.$_fetchModelData();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>
