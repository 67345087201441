import config from '@/__config'

export default {
  getXcProjectMenu(data, res) {
    if (!config.wsmodule.xc_project.personnel_cost) {
      return res
    }
    res.splice(1, 0, {
      title: "人事成本表",
      link: `/xc_project/${data.id}/personnel_cost`,
    });
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    if (!config.wsmodule.xc_project.personnel_cost) {
      return res
    }
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'personnel_cost',
        name: 'personnel_cost',
        component: () => import('@/__modules/xc_employee/extend_modules/xc_project/views/XcProject/PersonnelCost.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}