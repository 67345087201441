<template>
  <WsCard class="ws-board-card-001">
    <WsFlex
      :flexDirection="flexDirection"
      gap="16px"
    >
      <WsFlex
        flexDirection="column"
        gap="16px"
        v-for="(item,itemIndex) in items"
        :key="itemIndex"
      >
        <WsText size="16">{{ item.label }}</WsText>
        <WsFlex
          flexDirection="column"
          gap="8px"
          class="ws-board-card-001-main-content pl-8"
        >
          <WsFlex
            v-for="(subItem, subItemIndex) in item.items"
            :key="`subItem-${subItemIndex}`"
            justifyContent="space-between"
            gap="16px"
          >
            <WsText size="16">{{ subItem.label }}</WsText>
            <WsText class="flex-0">{{ subItem.value }}</WsText>
          </WsFlex>
        </WsFlex>
      </WsFlex>
      <WsLink
        v-if="urlText && url"
        :to="url"
        class="py-8"
      >
        <WsFlex
          justifyContent="flex-end"
          alignItems="center"
          gap="8px"
        >
          <WsText
            size="14"
            :color="$color.gray2d"
          >{{ urlText }}</WsText>
          <WsIcon
            name="icon-ws-outline-arrow-right"
            :color="$color.gray2d"
          />
        </WsFlex>
      </WsLink>
    </WsFlex>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    items: Array,
    urlText: String,
    url: String,
    flexDirection: {
      default: "column",
    },
  },
};
</script>

<style lang="scss">
.ws-board-card-001-main-content {
  border-left: 1px solid var(--primary1l);
}
</style>