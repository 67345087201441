const model = {
  options: {
  },
  modelName: 'testing_record',
  label: 'Testing Record',
  fields: {
    testing_result: {
      type: 'radio',
      label: '測試結果',
      items: [
        { value: 'pass', label: 'Pass' },
        { value: 'fail', label: 'Fail' },
      ]
    },
    testing_at: {
      type: 'datetime',
      label: '測試時間',
    },
    des: {
      type: 'textarea',
      label: '描述',
    },
    testing_admin: {
      type: 'belongsTo',
      label: '測試人員',
      modelName: 'admin',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "testing_result",
        "testing_at",
        "testing_admin",
        "des",
      ],
      inRowBtnRead: false,
    }
  }
}

export default model