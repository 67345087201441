<template>
  <WsFlex
    alignItems="center"
    class="mt-8"
    v-if="value"
  >
    <WsIcon name="icon-md-warning" />
    <WsText class="ml-4">{{value}}</WsText>
  </WsFlex>
</template>

<script>
export default {
  props: {
    value: {},
  },
};
</script>

<style>
</style>