<template>
  <WsMain class="pb-80">
    <WsStateForm
      :fields="fields.general"
      v-model="state"
    >
    </WsStateForm>
    <WsCard
      class="mt-20"
      v-for="(xc_task,xc_taskIndex) in state.xc_tasks"
      :key="xc_taskIndex"
    >
      <WsFlex>
        <WsStateForm
          :value="xc_task"
          :fields="_fields"
          @input="$_onInputTask($event, xc_taskIndex)"
        ></WsStateForm>
        <WsIconBtn
          @click="$_onRemoveTask(xc_taskIndex)"
          name="icon-md-delete"
        ></WsIconBtn>
      </WsFlex>
    </WsCard>
    <WsBtn
      class="mt-20"
      @click="$_onTaskAdd()"
    >新增Task</WsBtn>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        general: {
          xc_project: this.$store.state.stone_model.xc_task.fields.xc_project,
          relate_stored: {
            label: "關聯建立 Task",
            type: "switch",
          },
        },
      },
      state: {
        xc_project: null,
        relate_stored: false,
        xc_tasks: [],
      },
    };
  },
  methods: {
    async $_onSubmit() {
      const _xc_tasks = [];
      this.state.xc_tasks.forEach((xc_task) => {
        _xc_tasks.push({
          ...xc_task,
          xc_project: this.state.xc_project,
        });
      });
      const _state = {
        ...this.state,
        xc_tasks: _xc_tasks,
      };
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.post("/xc_task/batch", _state);
        this.$router.push("/xc_task");
      } catch (error) {
        alert(this.$t(this.$o_o.$h.http.getErrorMessageFromRes(error)));
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onRemoveTask(xc_taskIndex) {
      const _state = { ...this.state };
      _state.xc_tasks.splice(xc_taskIndex, 1);
      this.state = _state;
    },
    $_onInputTask($event, xc_taskIndex) {
      const _state = { ...this.state };
      _state.xc_tasks[xc_taskIndex] = $event;
      this.state = _state;
    },
    $_onTaskAdd() {
      this.state.xc_tasks.push({});
    },
  },
  computed: {
    _fields() {
      return {
        name: this.$store.state.stone_model.xc_task.fields.name,
        taker: this.$store.state.stone_model.xc_task.fields.taker,
        due_date: this.$store.state.stone_model.xc_task.fields.due_date,
        content: this.$store.state.stone_model.xc_task.fields.content,
      };
    },
  },
};
</script>

<style>
</style>