<template>
  <div
    class="layout-app"
    :class="[{ 'has-drawer': $store.state.app.hasDrawer }, { 'has-header': $store.state.app.hasHeader }, { 'bottom-nav': $store.state.app.hasBottomNav },{'paperclip-open':_paperClip}]"
  >
    <WsNavigationDrawer v-if="$store.state.app.hasDrawer"></WsNavigationDrawer>
    <WsAppHeader v-if="$store.state.app.hasHeader"></WsAppHeader>
    <WsAppWasajon></WsAppWasajon>
    <WsSnack></WsSnack>
    <WsAppAlert></WsAppAlert>
    <WsAppImagePreviewPopup />
    <WsAppModalMenu></WsAppModalMenu>
    <WsAppLoading></WsAppLoading>
    <WsAppStateDialog></WsAppStateDialog>
    <WsAppPaperclip></WsAppPaperclip>
    <div :class="['layout-app__container', {'desktop-menu-close': !_sideMenuOpen}]">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    _paperClip() {
      return this.$store.state.app.paperclip;
    },
    _sideMenuOpen() {
      return this.$store.state.app.sideMenuOpen;
    },
  },
};
</script>

<style lang="scss">
.layout-app {
  width: 100%;
  min-height: 100vh;
  &.paperclip-open {
    .layout-app__container {
      padding-right: 300px;
    }
  }
  &.has-drawer {
    .layout-app__container {
      padding-left: 240px;
      &.desktop-menu-close {
        padding-left: 0;
      }
      @media (max-width: 767px) {
        padding-left: 0;
      }
    }
    .ws-bottom-nav {
      width: calc(100% - 240px);
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  &.has-header {
    .layout-app__container {
      padding-top: 50px;
    }
  }
  &.bottom-nav {
    .layout-app__container {
      height: unset;
      padding-bottom: 60px;
    }
  }
  .layout-app__container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    > .ws-main {
      @media (max-width: 600px) {
        padding: 0;
      }
    }
  }
}
</style>