<template>
  <div>
    <WsCmsCrudClassify
      ref="WsCmsCrudClassify"
      fetchUrl="xc_employee_position/index/layers"
      modelName="xc_employee_position"
      label="職位"
      :updateFields="updateFields"
      :customTableActions="_customTableActions"
      emitCreate
      @onCreate="$_onCreate()"
      @custom-table-action="$_onCustomTableAction($event)"
    />
    <WsDialog
      title="新增 職位"
      type="short-panel"
      ref="WsDialog"
    >
      <template #content>
        <WsStateForm
          :fields="_createFields"
          v-model="createDialog.state"
        />
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onCreateDialogClose()"
          outlined
        >{{ $t('cancel') }}</WsBtn>
        <WsBtn @click="$_onCreateDialogSubmit()">{{ $t('submit') }}</WsBtn>
      </template>
    </WsDialog>
    <WsDialogBelongsToMany
      ref="WsDialogBelongsToMany"
      :id="belongsToMany.id"
      :label="belongsToMany.label"
      belongsToManyModelName="xc_employee"
      :belongsToManyFields="$store.state.stone_model.xc_employee.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      belongsToManyKey="xc_employees"
      modelName="xc_employee_position"
      paramsKey="xc_employee_position"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: this.$store.state.stone_model.xc_employee_position,
      updateFields: {
        name: this.$store.state.stone_model.xc_employee_position.fields.name,
      },
      createDialog: {
        state: {},
      },
      belongsToMany: {
        id: null,
        name: null,
        label: null,
        showFields: ["id", "name", "email"],
        pickShowFields: ["id", "name", "email"],
      },
    };
  },
  computed: {
    _createFields() {
      let _createFields = {};
      let model = this.$store.state.stone_model.xc_employee_position;
      let fields = model.crudSetting.create.primary[0].floors[0].fields;
      fields.forEach((item) => {
        _createFields[item] = model.fields[item];
      });
      return _createFields;
    },
    _customTableActions() {
      return this.model?.crudSetting?.index?.customTableActions || [];
    },
    // _belongsToManyModel() {
    //   return this.$config.wsmodule.admin.admin_blur ? "cmser" : "admin";
    // },
  },
  methods: {
    $_onCustomTableAction($event) {
      if ($event.emit == "update-admins") {
        this.belongsToMany.id = $event.data.classItem.id;
        this.belongsToMany.name = $event.data.classItem.name;
        this.belongsToMany.label = `人員管理 - ${$event.data.classItem.name}`;
        setTimeout(() => {
          this.$refs.WsDialogBelongsToMany.open();
        }, 0);
      }
    },
    $_onCreate() {
      this.$refs.WsDialog.open();
    },
    async $_onCreateDialogSubmit() {
      const postData = {
        ...this.createDialog.state,
      };
      try {
        await this.$o_o.$s._m.xc_employee_position.create(postData);
        this.$refs.WsCmsCrudClassify.$_fetchData();
        this.createDialog.state = {};
      } catch (error) {
        console.error(error);
        alert("新增失敗，請稍後再試");
      } finally {
        this.$refs.WsDialog.close();
      }
    },
    $_onCreateDialogClose() {
      this.$refs.WsDialog.close();
    },
  },
};
</script>
