<template>
  <WsMain>
    <WsModelMyIndex
      ref="WsModelMyIndex"
      :modelName="$store.state.stone_model.xc_leave_day.modelName"
      label="我的請假單"
      :fields="_fields"
      :inRowBtnRead="true"
      :updatable="false"
      :deletable="false"
      :showFields="showFields"
      :customTableActions="customTableActions"
      @item-cancel="$_onCancel($event)"
      @item-withdraw="$_onWithdraw($event)"
    />
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "type",
        "created_at",
        "start_at",
        "end_at",
        "days",
        "hours",
        "is_aboard",
        "status",
      ],
      customTableActions: [
        {
          text: "取消",
          emit: "item-cancel",
          displayCheck(item) {
            return item.status == "approved";
          },
        },
      ],
    };
  },
  methods: {
    async $_onCancel($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.my_cancel($event.item.id);
        this.$refs.WsModelMyIndex.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "canceled",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onWithdraw($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.my_withdraw($event.item.id);
        this.$refs.WsModelMyIndex.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "withdrawn",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _fields() {
      return {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_leave_day.fields,
          [
            "type",
            "created_at",
            "start_at",
            "end_at",
            "status",
            // "start_date",
            "days",
            "hours",
            "is_aboard",
            "note",
            "relate_files",
          ]
        ),
      };
    },
  },
};
</script>

<style>
</style>