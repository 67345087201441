<template>
  <WsFlex class="flex-full">
    <WsFullCalendar
      ref="WsFullCalendar"
      v-bind="$props"
      :events="_calendarEvents"
      :hasToolbar="hasToolbar"
      @drop="$emit('drop', $event)"
      @eventClick="$_onEventClick($event)"
      @eventDragStop="$emit('eventDragStop', $event)"
      @eventDrop="$_onEventDrop($event)"
      @datesSet="$_onDateSet($event)"
      @eventRemove="$_onEventRemove($event)"
    >
    </WsFullCalendar>
    <WsDialogCRUDRead
      class="short-panel"
      ref="WsDialogCRUDRead"
      :fields="_dialogReadFields"
      :showFields="_dialogReadShowFields"
      :modelName="dialogRead.currentModelName"
      :pageReadRedirect="_pageReadRedirect"
    />
  </WsFlex>
</template>

<script>
export default {
  data() {
    return {
      modelsdatas: {},
      fetchedDateRange: {
        start: null,
        end: null,
      },
      dialogRead: {
        currentModelName: "",
      },
    };
  },
  methods: {
    prev() {
      this.$refs.WsFullCalendar.prev();
    },
    next() {
      this.$refs.WsFullCalendar.next();
    },
    goDate($event) {
      this.$refs.WsFullCalendar.goDate($event);
    },
    changeView($event) {
      this.$refs.WsFullCalendar.changeView($event);
    },
    $_onEventClick($event) {
      const _modelName = $event.event.extendedProps?.modelName;
      if (!_modelName) {
        return;
      }
      this.dialogRead.currentModelName = _modelName;
      setTimeout(() => {
        this.$refs.WsDialogCRUDRead.open($event.event.extendedProps.id);
      }, 0);
    },
    $_onEventRemove($event) {
      this.$_removeModeldata($event.event.extendedProps);
      this.$emit("eventRemove", $event);
    },
    $_removeModeldata(modeldata) {
      if (this.modelsdatas[modeldata.modelName]) {
        const _index = this.modelsdatas[modeldata.modelName].findIndex((e) => {
          return e.id == modeldata.id;
        });
        if (_index >= 0) {
          this.modelsdatas[modeldata.modelName].splice(_index, 1);
        }
      }
    },
    $_checkEventDropStartAble($event) {
      if ($event.event.allDay) {
        return true;
      } else {
        return this.$o_o.$h.time.checkDropAble(
          $event.event.start,
          this.additionalOnDays,
          this.additionalOffDays,
          this.dayMinTime,
          this.dayMaxTime,
          this.skipWeekDays,
          this.skipTimesRange
        );
      }
    },
    $_onEventDrop($event) {
      $event.revert();
      const isEventDropStartAble = this.$_checkEventDropStartAble($event);
      if (!isEventDropStartAble) {
        return;
      }
      const isFromExtendModelsdatas =
        this.$_checkEventFromExtendModelsdatas($event);
      const isFromModelsdatas = this.$_checkEventFromModelsdatas($event);
      if (isFromExtendModelsdatas) {
        this.$_onEventDropExtendModelsdatas($event);
      }
      if (isFromModelsdatas) {
        this.$_onEventDropModelsdatas($event);
      }
      this.$emit("eventDrop", $event);
    },
    async $_onEventDropModelsdatas($event) {
      const modelName = $event.event.extendedProps.modelName;
      if (this.modelSettings[modelName].onChange) {
        const res = await this.modelSettings[modelName].onChange(
          $event.event.extendedProps,
          $event
        );
        if (res === false) {
          return;
        } else {
          if (this.modelSettings[modelName].updateModeldataOnEventDrop) {
            const _modeldata = this.modelSettings[
              modelName
            ].updateModeldataOnEventDrop($event.event.extendedProps, $event);
            this.$_updateModeldataInModelsdatas(_modeldata);
          }
        }
      }
    },
    $_onEventDropExtendModelsdatas($event) {
      this.$emit("onEventDropExtendModelsdatas", $event);
    },
    $_updateModeldataInModelsdatas(modeldata) {
      const modelName = modeldata.modelName;
      const _index = this.modelsdatas[modelName].findIndex((e) => {
        return e.id == modeldata.id;
      });
      const _modelsdatas = {
        ...this.modelsdatas,
      };
      _modelsdatas[modelName][_index] = {
        ..._modelsdatas[modelName][_index],
        ...modeldata,
      };
      this.modelsdatas = _modelsdatas;
    },
    $_checkEventFromExtendModelsdatas($event) {
      const modeldata = $event.event.extendedProps;
      const modelName = modeldata.modelName;
      if (!this.extendModelsdatas[modelName]) {
        return false;
      }
      return this.extendModelsdatas[modelName].some((item) => {
        return item.id == modeldata.id;
      });
    },
    $_checkEventFromModelsdatas($event) {
      const modeldata = $event.event.extendedProps;
      const modelName = modeldata.modelName;
      if (!this.modelsdatas[modelName]) {
        return false;
      }
      return this.modelsdatas[modelName].some((item) => {
        return item.id == modeldata.id;
      });
    },
    $_onDateSet($event) {
      this.$_setFetchedDateRangeFromCalendar();
      this.$emit("datesSet", $event);
    },
    getView() {
      return this.$refs.WsFullCalendar.getView();
    },
    $_fetchModelsDatas(start, end) {
      for (const key in this.modelSettings) {
        this.$_fetchModelDatas(key, this.modelSettings[key], start, end);
      }
    },
    $_setFetchedDateRangeFromCalendar() {
      const _calendarView = this.$refs.WsFullCalendar.getView();
      const start = this.$moment(_calendarView.currentStart).format(
        "YYYY-MM-DD"
      );
      const end = this.$moment(_calendarView.currentEnd).format("YYYY-MM-DD");
      if (!this.fetchedDateRange.start || this.fetchedDateRange.start > start) {
        this.fetchedDateRange.start = start;
      }
      if (!this.fetchedDateRange.end || this.fetchedDateRange.end < end) {
        this.fetchedDateRange.end = end;
      }
    },
    async $_fetchModelDatas(modelName, modelSetting, start, end) {
      const _fetchUrl = modelSetting.fetchUrl
        ? modelSetting.fetchUrl
        : `${modelName}/index/date`;
      const res = await this.$axios.get(_fetchUrl, {
        params: {
          start,
          end,
          ...this.params,
          ...modelSetting.index?.params,
        },
      });
      const _modeldatas = [
        ...(this.modelsdatas[modelName] ? this.modelsdatas[modelName] : []),
        ...res.data.data,
      ];
      const _modelsdatas = { ...this.modelsdatas };
      _modelsdatas[modelName] = _modeldatas;
      this.modelsdatas = _modelsdatas;
    },
    $_init() {
      this.$_setFetchedDateRangeFromCalendar();
      this.$_fetchModelsDatas(
        this.fetchedDateRange.start,
        this.fetchedDateRange.end
      );
    },
    $_reset() {
      this.fetchedDateRange.start = null;
      this.fetchedDateRange.end = null;
      this.modelsdatas = {};
      this.$_init();
    },
  },
  computed: {
    _dialogReadFields() {
      if (!this.dialogRead.currentModelName) {
        return {};
      }
      return this.$store.state.stone_model[this.dialogRead.currentModelName]
        .fields;
    },
    _dialogReadShowFields() {
      if (!this.dialogRead.currentModelName) {
        return [];
      }
      return this._currentModelSetting?.readDialog.showFields;
    },
    _currentModelSetting() {
      return this.modelSettings[this.dialogRead.currentModelName];
    },
    _pageReadRedirect() {
      return this._currentModelSetting?.pageReadRedirect;
    },
    _modelsDatasEvents() {
      const _modelsDatasEvents = [];
      for (const modelName in this.modelsdatas) {
        const modeldatas = this.modelsdatas[modelName];
        modeldatas.forEach((modeldata) => {
          const _event = this.$o_o.$h._m[modelName].toCalendarEvent(
            modeldata,
            this.additionalOnDays,
            this.additionalOffDays
          );
          _modelsDatasEvents.push(_event);
        });
      }
      return _modelsDatasEvents;
    },
    _extendModelsdatasEvents() {
      const _extendModelsdatasEvents = [];
      for (const modelName in this.extendModelsdatas) {
        const extendModeldatas = this.extendModelsdatas[modelName];
        extendModeldatas.forEach((extendModeldata) => {
          const _event = this.$o_o.$h._m[modelName].toCalendarEvent(
            extendModeldata,
            this.additionalOnDays,
            this.additionalOffDays
          );
          _extendModelsdatasEvents.push(_event);
        });
      }
      return _extendModelsdatasEvents;
    },
    _calendarEvents() {
      return [
        ...this._modelsDatasEvents,
        ...this._extendModelsdatasEvents,
        ...this.events,
      ];
    },
  },
  mounted() {
    this.$_init();
  },
  watch: {
    "fetchedDateRange.start": {
      handler(newVal, oldVal) {
        if (!oldVal) {
          return;
        }
        this.$_fetchModelsDatas(
          newVal,
          this.$moment(oldVal, "YYYY-MM-DD").add(-1, "day").format("YYYY-MM-DD")
        );
      },
    },
    "fetchedDateRange.end": {
      handler(newVal, oldVal) {
        if (!oldVal) {
          return;
        }
        this.$_fetchModelsDatas(
          this.$moment(oldVal, "YYYY-MM-DD")
            .add(+1, "day")
            .format("YYYY-MM-DD"),
          newVal
        );
      },
    },
    params: {
      handler() {
        this.$_reset();
      },
    },
  },
  props: {
    extendModelsdatas: {},
    modelSettings: {},
    height: {},
    hiddenDays: {},
    droppable: {},
    editable: {},
    params: {},
    additionalOnDays: {},
    additionalOffDays: {},
    dayMinTime: {
      type: String,
      default: "09:00:00",
    },
    dayMaxTime: {
      type: String,
      default: "18:00:00",
    },
    skipWeekDays: {
      type: Array,
      default() {
        return [0, 6];
      },
    },
    skipTimesRange: {
      type: Array,
      default() {
        return ["12:00:00", "13:00:00"];
      },
    },
    events: {
      default() {
        return [];
      },
    },
    hasToolbar: {},
  },
};
</script>