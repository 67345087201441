<template>
  <WsMain>
    <ValidationObserver ref="form">
      <WsStateForm
        :fields="fields"
        v-model="state"
      ></WsStateForm>
    </ValidationObserver>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        subject: {
          type: "text",
          label: "Subject",
          rules: "required",
        },
        recipient: {
          type: "email",
          label: "Recipient",
          rules: "required|email",
        },
        html_content: {
          type: "textarea",
          label: "Html Content (No <html>, <head>)",
          rules: "required",
        },
      },
      state: {
        subject: "test",
        recipient: "",
        html_content: "<div>test</div><p>asfasdfasfadsf</p>",
      },
      loading: {
        submit: false,
      },
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      try {
        this.loading.submit = true;
        await this.$o_o.$s._m.testing.mail(this.state);
        this.$store.dispatch("app/addSnack", `${this.$t("信件已寄出")}!`);
      } catch (error) {
        console.error("error", error);
      } finally {
        this.loading.submit = false;
      }
    },
  },
};
</script>

<style>
</style>