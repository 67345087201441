const scopes = [
  {
    text: '加班單 管理',
    value: 'xc_work_overtime-admin',
    tags: []
  },
  {
    text: '下屬 加班單 管理',
    value: 'xc_work_overtime-subordinate-admin'
  },
]

export default scopes