const model = {
  fields: {
    xc_project: {
      type: 'belongsTo',
      label: '計畫',
      textKey: "name",
      modelName: "xc_project",
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_project'
      ]
    },
  }
}
export default model