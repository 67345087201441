<template>
  <div>
    <WsBoardCard001
      v-if="_cardProps"
      flexDirection="row"
      v-bind="_cardProps"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showInAvailableFields: [
        "annual_leave",
        "annual_leave_deferred",
        "annual_leave_from_work_overtime",
        "personal_leave",
        "sick_leave",
        "family_care_leave",
        "menstrual_leave",
      ],
      showFields: [
        "annual_leave",
        "annual_leave_deferred",
        "annual_leave_from_work_overtime",
        "personal_leave",
        "sick_leave",
        "family_care_leave",
        "menstrual_leave",
        "marriage_leave",
        "funeral_leave",
        "funeral_leave_8",
        "funeral_leave_6",
        "funeral_leave_3",
        "occupational_injury_leave",
        "paternity_leave",
        "maternity_leave_56",
        "maternity_leave_28",
        "maternity_leave_7",
        "maternity_leave_5",
        "maternity_check_leave",
        "miscarriage_leave",
        "teaching_leave",
        "study_leave",
        "vaccine_leave",
        "quarantine_leave",
        "quarantine_homecoming_leave",
        "epidemic_prevention_care_leave",
        "official_leave_employee_trip",
        "official_leave_statutory",
        "official_leave_health_check_up",
      ],
    };
  },
  computed: {
    _cardProps() {
      if (!this.overview) {
        return null;
      }
      const _availableItems = [];
      const _usedItems = [];
      const _remainItems = [];
      this.showFields.forEach((field) => {
        if (this.overview[field]) {
          if (this.showInAvailableFields.includes(field)) {
            _availableItems.push({
              label: this.overview[field].name,
              value: `${this.overview[field].total_days_and_hours_days}天 ${this.overview[field].total_days_and_hours_hours}小時`,
            });
            _remainItems.push({
              label: this.overview[field].name,
              value: `${this.overview[field].remain_days_and_hours_days}天 ${this.overview[field].remain_days_and_hours_hours}小時`,
            });
            _usedItems.push({
              label: this.overview[field].name,
              value: `${this.overview[field].used_days_and_hours_days}天 ${this.overview[field].used_days_and_hours_hours}小時`,
            });
          } else if (this.overview[field]?.used_hours > 0) {
            console.log("ok");
            _usedItems.push({
              label: this.overview[field].name,
              value: `${this.overview[field].used_days_and_hours_days}天 ${this.overview[field].used_days_and_hours_hours}小時`,
            });
          }
        }
      });

      return {
        items: [
          {
            label: "可用休假餘額",
            items: _availableItems,
          },
          {
            label: "已用休假餘額",
            items: _usedItems,
          },
          {
            label: "剩餘休假餘額",
            items: _remainItems,
          },
        ],
      };
    },
  },
  props: {
    overview: {},
  },
};
</script>

<style>
</style>