const model = {
  fields: {
    xc_project: {
      type: 'belongsTo',
      label: '計畫',
      textKey: "name",
      modelName: "xc_project",
      rules: 'required',
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_project'
      ]
    },
    index_my: {
      showFields: [
        'xc_project'
      ]
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_project'
              ],
            },
          ],
        },
      ],
    },
  }
}
export default model