<template>
  <WsMain>
    <WsActionApi
      :fetchUrl="`xc_employee/calc/cost`"
      @fetch="$_onFetch($event)"
      :params="_params"
    >
    </WsActionApi>
    <div v-if="res">
      <WsText>{{res.xc_employee.name}}</WsText>
      <WsExcel
        class="mt-20"
        :fields="_fields"
        :value="_value"
        :readonly="true"
      ></WsExcel>
    </div>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      res: null,
    };
  },
  methods: {
    $_onFetch($event) {
      this.res = $event;
    },
  },
  computed: {
    _value() {
      if (!this.res) {
        return [];
      }
      const _value = [];
      for (const calc_item of this.res.calc.calc_items) {
        _value.push({
          is_predict: calc_item.is_predict ? "預估" : "實際",
          tw_year: calc_item.tw_year,
          month: calc_item.month,
          basic_salary: calc_item.basic_salary,
          meal_expenses: calc_item.meal_expenses,
          work_allowance: calc_item.work_allowance,
          transportation_allowance: calc_item.transportation_allowance,
          xc_leave_deduct: calc_item.xc_leave_deduct,
          tw_labor_insurance_employer: calc_item.tw_labor_insurance_employer,
          tw_health_insurance_employer: calc_item.tw_health_insurance_employer,
          tw_labor_pension_employer: calc_item.tw_labor_pension_employer,
          sum: calc_item.sum,
        });
      }
      _value.push({
        is_predict: "",
        tw_year: this.res.calc.performance_bonus.tw_year,
        month: "春節",
        performance_bonus: this.res.calc.performance_bonus.performance_bonus,
        tw_health_insurance_employer:
          this.res.calc.performance_bonus.tw_health_insurance_employer,
        sum: this.res.calc.performance_bonus.sum,
      });
      _value.push({
        basic_salary: this.res.calc.total.basic_salary,
        meal_expenses: this.res.calc.total.meal_expenses,
        work_allowance: this.res.calc.total.work_allowance,
        transportation_allowance: this.res.calc.total.transportation_allowance,
        xc_leave_deduct: this.res.calc.total.xc_leave_deduct,
        performance_bonus: this.res.calc.total.performance_bonus,
        tw_labor_insurance_employer:
          this.res.calc.total.tw_labor_insurance_employer,
        tw_health_insurance_employer:
          this.res.calc.total.tw_health_insurance_employer,
        tw_labor_pension_employer:
          this.res.calc.total.tw_labor_pension_employer,
        sum: this.res.calc.total.sum,
      });
      return _value;
    },
    _fields() {
      return {
        is_predict: {
          label: "預估/實際",
          type: "text",
        },
        tw_year: {
          label: "年",
          type: "text",
          width: 50,
        },
        month: {
          label: "月",
          type: "text",
          width: 50,
        },
        basic_salary: {
          label: "本俸",
          type: "currency",
        },
        meal_expenses: {
          type: "currency",
          label: "伙食費",
        },
        work_allowance: {
          type: "currency",
          label: "工作加給",
        },
        transportation_allowance: {
          type: "currency",
          label: "交通津貼",
        },
        performance_bonus: {
          type: "currency",
          label: "績效獎金",
        },
        xc_leave_deduct: {
          type: "currency",
          label: "事病假扣款",
          width: 100,
        },
        tw_labor_insurance_employer: {
          type: "currency",
          label: "公提勞保",
        },
        tw_health_insurance_employer: {
          type: "currency",
          label: "公提健保",
        },
        tw_labor_pension_employer: {
          type: "currency",
          label: "退休專戶",
        },
        sum: {
          type: "currency",
          label: "總計",
        },
      };
    },
    _params() {
      return {
        xc_employee: this._xc_employee,
        start_at: this._start_at,
        end_at: this._end_at,
      };
    },
    _xc_employee() {
      return this.$route.query.xc_employee;
    },
    _start_at() {
      return this.$route.query.start_at;
    },
    _end_at() {
      return this.$route.query.end_at;
    },
  },
};
</script>

<style>
</style>