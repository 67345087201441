<template>
  <div>
    <slot
      :res="res"
      :isFetching="isFetching"
    ></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      res: null,
      isFetching: false,
      cancelTokenSource: null,
    };
  },
  methods: {
    $_release() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
    },
    $_loadingStart() {
      if (this.globalLoading) {
        this.$store.dispatch("app/startPageLoading");
      } else {
        this.isFetching = true;
      }
    },
    $_loadingEnd() {
      if (this.globalLoading) {
        this.$store.dispatch("app/stopPageLoading");
      } else {
        this.isFetching = false;
      }
    },
    async $_fetch() {
      try {
        this.$_release();
        this.$_loadingStart();
        this.cancelTokenSource = this.$axios.CancelToken.source();
        const _res = await this.$axios.get(this.fetchUrl, {
          params: this.params,
          cancelToken: this.cancelTokenSource.token,
        });
        this.$emit("fetch", _res.data);
        this.res = _res.data;
      } catch (error) {
        if (error.__CANCEL__) {
          return;
        }
        alert(this.$o_o.$h.http.getErrorMessageFromRes(error));
      } finally {
        this.$_loadingEnd();
      }
    },
  },
  watch: {
    params: {
      handler() {
        this.$_fetch();
      },
    },
    fetchUrl: {
      handler() {
        this.$_fetch();
      },
    },
  },
  mounted() {
    this.$_fetch();
  },
  props: {
    fetchUrl: {},
    params: {},
    globalLoading: {},
  },
};
</script>