<template>
  <WsCard
    class="ws-collapse pa-4"
    :class="[
      { 'ws-collapse-open': isOpen },
    ]"
  >
    <div @click="$_onClickHeader">
      <WsFlex class="ws-collapse-header">
        <div class="ws-collapse-header__content">
          <slot name="header"></slot>
        </div>
        <WsSpacer></WsSpacer>
        <WsIcon
          class="ws-collapse-header__icon"
          name="icon-md-arrow-drop-down"
          :size="24"
        ></WsIcon>
      </WsFlex>
    </div>
    <div class="ws-collapse-content">
      <slot name="content"></slot>
    </div>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    $_onClickHeader() {
      console.log("$_onClickHeader");
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.ws-collapse {
  &.ws-collapse-open {
    .ws-collapse-header {
      .ws-collapse-header__icon {
        transform: rotate(180deg);
      }
    }
    .ws-collapse-content {
      display: block;
    }
  }
  .ws-collapse-header {
    cursor: pointer;
    padding: 10px 20px;
    .ws-collapse-header__content {
      flex: 1;
    }
    .ws-collapse-header__icon {
      transition: transform 0.3s;
    }
  }
  .ws-collapse-content {
    display: none;
    padding: 20px;
  }
}
</style>