<template>
  <WsModelIndex
    ref="WsModelIndex"
    :modelName="modelName"
    :label="label"
    :fields="fields"
    :urlModelName="urlModelName"
    :params="_params"
    :createUrl="createUrl"
    :getUpdateUrl="getUpdateUrl"
    :getReadUrl="getReadUrl"
    :orderLayerFields="_orderLayerFields"
    @title-bar-custom-btn-click="$emit('title-bar-custom-btn-click', $event)"
    v-bind="_bindingProps"
    @params="$emit('params', $event)"
    @custom-table-action="$emit('custom-table-action', $event)"
    :creatable="_creatable"
    :updatable="_updatable"
    :deletable="_deletable"
    :filterTabs="_filterTabs"
    :getUrl="getUrl"
    :hasFilter="hasFilter"
    :showFields="_showFields"
    :hasFilterSelect="hasFilterSelect"
    :customTableActions="_customTableActions"
    :inRowBtnRead="_inRowBtnRead"
    :inRowBtnUpdate="_inRowBtnUpdate"
    :inRowBtnDelete="_inRowBtnDelete"
    :sortOption="sortOption"
    :createData="createData"
    :updateData="updateData"
    :dialogCreateSubmitUrl="dialogCreateSubmitUrl"
    :getDialogUpdateSibmitUrl="getDialogUpdateSibmitUrl"
    :getDeleteSubmitUrl="getDeleteSubmitUrl"
    :getReadFetchUrl="getReadFetchUrl"
    :infiniteScroll="_infiniteScroll"
    @update:filter-select-form="$emit('update:filter-select-form', $event)"
    @input="$emit('input', $event)"
  ></WsModelIndex>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.label,
    };
  },
  methods: {
    fetchData() {
      this.$refs.WsModelIndex.reFetch();
    },
    reset() {
      this.$refs.WsModelIndex.reset();
    },
    updateModelData(modeldata) {
      this.$refs.WsModelIndex.updateModelData(modeldata);
    },
  },
  computed: {
    _bindingProps() {
      return {
        pageMode: this.pageMode,
        extendPostData: this.extendPostData,
        ...this._crudSetting[this.bindingKey],
      };
    },
    _customTableActions() {
      if (this._crudSetting[this.bindingKey].customTableActions != undefined) {
        return this._crudSetting[this.bindingKey].customTableActions;
      } else {
        return this.customTableActions;
      }
    },
    _orderLayerFields() {
      if (this._crudSetting[this.bindingKey].orderLayerFields != undefined) {
        return this._crudSetting[this.bindingKey].orderLayerFields;
      } else {
        return this.orderLayerFields;
      }
    },
    _inRowBtnRead() {
      if (this._crudSetting[this.bindingKey].inRowBtnRead != undefined) {
        return this._crudSetting[this.bindingKey].inRowBtnRead;
      } else {
        return this.inRowBtnRead;
      }
    },
    _inRowBtnUpdate() {
      if (this._crudSetting[this.bindingKey].inRowBtnUpdate != undefined) {
        return this._crudSetting[this.bindingKey].inRowBtnUpdate;
      } else {
        return this.inRowBtnUpdate;
      }
    },
    _inRowBtnDelete() {
      if (this._crudSetting[this.bindingKey].inRowBtnDelete != undefined) {
        return this._crudSetting[this.bindingKey].inRowBtnDelete;
      } else {
        return this.inRowBtnDelete;
      }
    },
    _infiniteScroll() {
      if (this._crudSetting[this.bindingKey].infiniteScroll != undefined) {
        return this._crudSetting[this.bindingKey].infiniteScroll;
      } else {
        return this.infiniteScroll;
      }
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else {
        return this._crudSetting.index.showFields;
      }
    },
    _crudSetting() {
      if (this.crudSetting) {
        return this.crudSetting;
      } else {
        return {
          [this.bindingKey]: {
            showFields: this.$o_o.$h.model.getShowFieldsFromFields(this.fields),
          },
        };
      }
    },
    _creatable() {
      if (
        this._crudSetting &&
        this._crudSetting[this.bindingKey] &&
        this._crudSetting[this.bindingKey].creatableScopes
      ) {
        if (
          this.$o_o.$h.auth.hasScope(
            this._crudSetting[this.bindingKey].creatableScopes
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return this._crudSetting[this.bindingKey].creatable;
      }
    },
    _updatable() {
      if (
        this._crudSetting &&
        this._crudSetting[this.bindingKey] &&
        this._crudSetting[this.bindingKey].updatableScopes
      ) {
        if (
          this.$o_o.$h.auth.hasScope(
            this._crudSetting[this.bindingKey].updatableScopes
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return this._crudSetting[this.bindingKey].updatable;
      }
    },
    _deletable() {
      if (
        this._crudSetting &&
        this._crudSetting[this.bindingKey] &&
        this._crudSetting[this.bindingKey].deletableScopes
      ) {
        if (
          this.$o_o.$h.auth.hasScope(
            this._crudSetting[this.bindingKey].deletableScopes
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return this._crudSetting[this.bindingKey].deletable;
      }
    },
    _params() {
      let _params = this._crudSetting[this.bindingKey].params;
      if (
        this._crudSetting[this.bindingKey].countryCode &&
        this.$store.state.auth.currentUser.country_code
      ) {
        _params = {
          ...this._crudSetting[this.bindingKey].params,
          country_code: this.$store.state.auth.currentUser.country_code,
        };
      }
      if (this.params) {
        _params = {
          ..._params,
          ...this.params,
        };
      }
      return _params;
    },
    _filterTabs() {
      if (this._crudSetting[this.bindingKey].filterTabs) {
        return this._crudSetting[this.bindingKey].filterTabs;
      } else {
        return this.filterTabs;
      }
    },
  },
  props: {
    showFields: {
      type: Array,
    },
    hasFilter: {
      type: Boolean,
      default: true,
    },
    hasFilterSelect: {
      type: Boolean,
      default: true,
    },
    bindingKey: {
      type: String,
      default: "index",
    },
    sortOption: {
      type: Object,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    modelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    crudSetting: {
      type: Object,
    },
    createUrl: {
      type: String,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    urlModelName: {
      type: String,
    },
    orderLayerFields: {
      type: Array,
    },
    creatableScopes: {
      type: Array,
    },
    updatableScopes: {
      type: Array,
    },
    deletableScopes: {
      type: Array,
    },
    creatable: {
      type: Boolean,
    },
    updatable: {
      type: Boolean,
    },
    deletable: {
      type: Boolean,
    },
    filterTabs: {
      type: Array,
    },
    params: {
      type: Object,
    },
    getUrl: {
      type: String,
    },
    filterSelects: {
      type: Object,
    },
    customTableActions: {},
    createData: {},
    updateData: {},
    pageMode: {},
    dialogCreateSubmitUrl: {},
    getDialogUpdateSibmitUrl: {},
    getDeleteSubmitUrl: {},
    getReadFetchUrl: {},
    getFields: {},
    getCreateFields: {},
    getUpdateFields: {},
    extendPostData: {},
  },
};
</script>