<template>
  <tr
    class="file-system-drive-list-file"
    @drag="$_onDrag($event)"
  >
    <td class="pa-8">
      <WsLink
        class="flex-1"
        :href="url"
        target="_blank"
      >
        <WsFlex>
          <FileIcon :ext="_fileExtention"></FileIcon>
          <WsText
            class="ml-8"
            :color="$color.link"
          >{{ file_name }}</WsText>
        </WsFlex>
      </WsLink>
    </td>
    <td class="pa-8">
      <FileSize :value="file_size"></FileSize>
    </td>
    <td class="pa-8">
      <WsText>{{ $moment(updated_at).format('YYYY.MM.DD HH:mm:ss') }}</WsText>
    </td>
    <td>
      <WsFlex
        justifyContent="flex-end"
        class="file-system-drive-list-file__actions"
      >
        <WsIconBtn
          class="mx-4"
          tooltip="Delete"
          name="icon-md-delete"
          @click="$_onDelete()"
        ></WsIconBtn>
      </WsFlex>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    file_name: {},
    file_size: {},
    updated_at: {},
    url: {},
    id: {},
  },
  methods: {
    $_onDelete() {
      this.$store.dispatch("app/setAlert", {
        title: "確定刪除檔案？",
        description: "",
        action: this.$_onSubmitDelete,
      });
    },
    async $_onSubmitDelete() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.delete(`/drive/file/${this.id}`);
        this.$emit("delete");
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onDrag($event) {
      console.log("$_onDrag", $event);
    },
  },
  computed: {
    _fileExtention() {
      return this.$o_o.$h.file.getFileExtension(this.file_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-system-drive-list-file {
  &:hover {
    background-color: var(--white4d);
    .file-system-drive-list-file__actions {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .file-system-drive-list-file__actions {
    opacity: 0;
    pointer-events: none;
  }
}
</style>