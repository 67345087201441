<template>
  <div>
    <WsModelIndex
      ref="WsModelIndex"
      :label="$store.state.stone_model.testing_case.label"
      :modelName="$store.state.stone_model.testing_case.modelName"
      :fields="$store.state.stone_model.testing_case.fields"
      :filterTabs="filterTabs"
      :showFields="$store.state.stone_model.testing_case.crudSetting.index.showFields"
      :creatable="true"
      :updatable="true"
      :deletable="true"
      :readable="true"
      :actionWidth="140"
      :pageMode="true"
      :createFields="_createFields"
      :updateFields="_updateFields"
      :paramsOnCrud="_paramsOnCrud"
      :getReadUrl="$_getReadUrl"
      :getUpdateUrl="$_getUpdateUrl"
      :createUrl="`/xc_project/${this._id}/testing_case/create`"
    ></WsModelIndex>
  </div>
</template>

<script>
export default {
  methods: {
    $_getReadUrl(item) {
      return `/xc_project/${this._id}/testing_case/${item.id}`;
    },
    $_getUpdateUrl(item) {
      return `/xc_project/${this._id}/testing_case/${item.id}/update`;
    },
  },
  computed: {
    _createFields() {
      const _createFields = {
        ...this.$store.state.stone_model.testing_case.fields,
      };
      delete _createFields.xc_project;
      return _createFields;
    },
    _updateFields() {
      const _updateFields = {
        ...this.$store.state.stone_model.testing_case.fields,
      };
      delete _updateFields.xc_project;
      return _updateFields;
    },
    _id() {
      return this.$route.params.id;
    },
    _paramsOnCrud() {
      return {
        xc_project: this._id,
      };
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
