<template>
  <WsText>{{ _size }}</WsText>
</template>

<script>
export default {
  props: {
    value: {
      default: 0,
    },
  },
  computed: {
    _size() {
      if (this.value === null) {
        return "-";
      }
      if (this.value < 1024) {
        return `${this.value} B`;
      } else if (this.value < 1024 * 1024) {
        return `${(this.value / 1024).toFixed(2)} KB`;
      } else if (this.value < 1024 * 1024 * 1024) {
        return `${(this.value / 1024 / 1024).toFixed(2)} MB`;
      } else {
        return `${(this.value / 1024 / 1024 / 1024).toFixed(2)} GB`;
      }
    },
  },
};
</script>

<style>
</style>