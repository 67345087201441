<template>
  <WsMain>
    <FileSystemDriveIndex
      :key="$route.params.folder_id"
      :folder="$route.params.folder_id"
    ></FileSystemDriveIndex>
  </WsMain>
</template>

<script>
export default {};
</script>

<style>
</style>