<template>
  <div>
    <FileSystemDriveIndex
      :urlPrefix="`/xc_project/${xc_project.id}`"
      :folder="$route.params.folder_id"
    ></FileSystemDriveIndex>
  </div>
</template>

<script>
export default {
  computed: {},
  data() {
    return {};
  },
  props: {
    xc_project: {},
  },
};
</script>

<style></style>
