import H_Router from '@/__stone/helpers/router'
import H_Module from '@/__stone/helpers/module'


const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['create', 'update', 'batchcreate'],
    modelName: 'xc_project',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_project-admin'],
    },
  }),
  {
    path: '/xc_project',
    name: 'xc_project_index',
    component: () => import('@/__modules/xc_project/views/XcProject/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/xc_project/:id',
    name: 'xc_project_redirect',
    redirect: to => {
      let _path = `/xc_project/${to.params.id}/info`
      _path = H_Module.extendModuleHelperAction(
        "xc_project",
        "xc_project.js",
        "getXcProjectRedirectPath",
        {
          id: to.params.id,
        },
        _path
      )
      return {
        path: _path
      }
    },
  },
  {
    path: '/xc_project/:id',
    name: 'xc_project',
    component: () => import('@/__modules/xc_project/views/XcProject/Read.vue'),
    meta: {
    },
    children: [
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/__modules/xc_project/views/XcProject/Info.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'member',
        name: 'Member',
        component: () => import('@/__modules/xc_project/views/XcProject/Member.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'member_month_day/create',
        name: 'member_month_day',
        component: () => import('@/__modules/xc_project/views/XcProject/MemberMonthDay/Create.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'member_month_day/:member_month_day_id/update',
        name: 'member_month_day_update',
        component: () => import('@/__modules/xc_project/views/XcProject/MemberMonthDay/Update.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'member_month_day',
        name: 'member_month_day_index',
        component: () => import('@/__modules/xc_project/views/XcProject/MemberMonthDay/Index.vue'),
        meta: {
          middleware: 'auth',
        },
        children: [
          {
            path: ':member_month_day_id',
            name: 'show',
            component: () => import('@/__modules/xc_project/views/XcProject/MemberMonthDay/Show.vue'),
            meta: {
              middleware: 'auth',
            },
          },
        ]
      },
      ...(H_Module.extendModuleHelperAction(
        "xc_project",
        "xc_project.js",
        "getXcProjectExtendRoutes",
      ) || [])
    ]
  },
]
export default routes