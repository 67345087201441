const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'testing_case_template_item',
  label: 'TestCase Template Item',
  fields: {
    des: {
      type: 'textarea',
      label: '測試內容',
    },
  },
}

export default model