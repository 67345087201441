<template>
  <div class="ws-app-state-dialog">
    <WsDialog
      ref="WsDialog"
      class="short-panel"
      :title="$store.state.app.stateDialog.title"
      :contentHasPadding="true"
    >
      <template #content>
        <!-- <slot name="content"></slot> -->
        <WsStateForm
          v-if="$store.state.app.stateDialog.fields"
          v-model="state"
          :fields="$store.state.app.stateDialog.fields"
        ></WsStateForm>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onClose()"
          :outlined="true"
        >{{ $t('cancel') }}</WsBtn>
        <WsBtn @click="$_onSubmit()">{{ $t('submit') }}</WsBtn>
      </template>
    </WsDialog>
  </div>

</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  methods: {
    $_onClose() {
      this.$refs.WsDialog.close();
      this.state = {};
    },
    $_onOpen() {
      this.state = {
        ...this.$store.state.app.stateDialog.data,
      };
      this.$refs.WsDialog.open();
    },
    async $_onSubmit() {
      await this.$store.state.app.stateDialog.onSubmit(
        this.state,
        this.$store.state.app.stateDialog.data,
        this
      );
      this.$_onClose();
    },
  },
  watch: {
    "$store.state.app.stateDialog": {
      handler() {
        if (this.$store.state.app.stateDialog) {
          this.$_onOpen();
        } else {
          this.$_onClose();
        }
      },
    },
  },
};
</script>

<style>
</style>