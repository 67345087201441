// import config from '@/__config';

const model = {
  modelName: "xc_leave_day_adjustment",
  label: "休假數量變更",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    // admin: {
    //   type: "belongsTo",
    //   label: "申請人",
    //   textKey: "name",
    //   modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    // },
    xc_employee: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: 'xc_employee'
    },
    type: {
      type: 'select',
      label: '假別',
      defaultValue: 'annual_leave',
      items: [
        {
          text: '特休假',
          value: 'annual_leave',
        },
        {
          text: '病假',
          value: 'sick_leave',
        },
        {
          text: '事假',
          value: 'personal_leave',
        },
        {
          text: '生理假',
          value: 'menstrual_leave',
        },
        {
          text: '喪假',
          value: 'funeral_leave',
        },
      ],
    },
    days: {
      type: 'number',
      label: '調整天數'
    },
    hours: {
      type: 'number',
      label: '調整時數'
    },
    note: {
      type: 'textarea',
      label: '備註'
    },
    adjust_start_at: {
      type: 'datetime',
      label: '計算起始時間',
    },
    adjust_end_at: {
      type: 'datetime',
      label: '計算結束時間'
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      showFields: ['xc_employee', 'type', 'days', 'hours', 'adjust_start_at', 'adjust_end_at'],
      readable: true,
      updatable: true,
      deletable: true,
      dialogRead: true,
    },
    create: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['xc_employee', 'type', 'days', 'hours', 'adjust_start_at', 'adjust_end_at', 'note'],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['xc_employee', 'type', 'days', 'hours', 'adjust_start_at', 'adjust_end_at', 'note'],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: ['id', 'xc_employee', 'type', 'days', 'hours', 'adjust_start_at', 'adjust_end_at', 'note'],
      titleKey: 'name',
    },
  }
}

export default model