<template>
  <WsFlex>
    <WsCollapse>
      <template v-slot:header>
        <WsFlex>
          <TestingResultLabel
            :value="_last_record_testing_result"
            class="mr-8"
          ></TestingResultLabel>
          <WsText>{{ testing_case.name }}</WsText>
        </WsFlex>
      </template>
      <template v-slot:content>
        <WsInfoForm
          :fields="_fields"
          :value="testing_case"
        ></WsInfoForm>
      </template>
    </WsCollapse>
    <WsBtn
      class="px-4 ml-8"
      :minWidth="20"
      small
      @click="$_onClickPass"
    >Pass</WsBtn>
    <WsBtn
      class="px-4 ml-8"
      :minWidth="20"
      small
      outlined
      @click="$_onClickFail"
    >Fail</WsBtn>
  </WsFlex>
</template>

<script>
export default {
  props: {
    testing_case: Object,
  },
  methods: {
    async $_onPass(state, data) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(`/testing_case/${data.id}/pass`, {
          des: state.des,
        });
        this.$store.dispatch("app/addSnack", `送出成功`);
        this.testing_case.testing_records.unshift({
          testing_result: "pass",
        });
      } catch (error) {
        this.$o_o.$h.http.getErrorMessageFromRes(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onClickPass() {
      this.$store.dispatch("app/setStateDialog", {
        fields: {
          des: this.$store.state.stone_model.testing_case.fields.des,
        },
        data: {
          id: this.testing_case.id,
          item: this.testing_case,
        },
        title: "Pass",
        onSubmit: this.$_onPass,
      });
    },
    async $_onFail(state, data) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(`/testing_case/${data.id}/fail`, {
          des: state.des,
        });
        this.$store.dispatch("app/addSnack", `送出成功`);
        this.testing_case.testing_records.unshift({
          testing_result: "fail",
        });
      } catch (error) {
        this.$o_o.$h.http.getErrorMessageFromRes(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onClickFail() {
      this.$store.dispatch("app/setStateDialog", {
        fields: {
          des: this.$store.state.stone_model.testing_case.fields.des,
        },
        data: {
          id: this.testing_case.id,
          item: this.testing_case,
        },
        title: "Fail",
        onSubmit: this.$_onFail,
      });
    },
  },
  computed: {
    _last_record_testing_result() {
      if (!this._last_record) {
        return "wait";
      } else {
        return this._last_record.testing_result;
      }
    },
    _last_record() {
      if (!this.testing_case.testing_records.length) {
        return null;
      } else {
        return this.testing_case.testing_records[0];
      }
    },
    _fields() {
      const _fields = { ...this.$store.state.stone_model.testing_case.fields };
      delete _fields.id;
      delete _fields.name;
      delete _fields.priority;
      delete _fields.testing_case_template;
      return _fields;
    },
  },
};
</script>

<style>
</style>