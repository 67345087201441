import config from '@/__config';
import { default as auth, default as serviceAuth } from '@/__stone/service/api/v1/auth';
import serviceLocale from '@/__stone/service/api/v1/locale';
import store from '@/store';
import * as changeCase from 'change-case';
import H_Module from './module';

export default {
  async middlewareCheck(to, from, next) {
    // CurrentUser Set
    const accessToken = auth.getToken();
    let currentUser;
    if (accessToken && !store.state.auth.currentUser) {
      try {
        currentUser = await serviceAuth.getUser();
        auth.setToken(accessToken);
      } catch (error) {
        currentUser = null;
      }
    } else {
      currentUser = store.state.auth.currentUser;
    }

    // Locale Set
    if (config.locale.indexFromApi) {
      serviceLocale.setLocalesFromAPI();
    }

    // Auth Check
    const requiresAuth = to.matched.some(
      (record) => record.meta.middleware && record.meta.middleware == 'auth',
    );

    if (requiresAuth && !currentUser) {
      const query = to.path && to.path != '/' ? { redirect: to.path } : {};
      next({ path: '/login', query: query });
      return;
    }

    // Login Check
    if (to.name == 'login') {
      if (!currentUser) {
        next();
        return;
      } else {
        next('/');
        return;
      }
    }

    // Logout Check
    if (to.name == 'logout') {
      if (!currentUser) {
        next('/login');
        return;
      } else {
        next();
        return;
      }
    }

    // Scope Check
    const requiredScopes =
      to.meta && to.meta.requiredScopes ? to.meta.requiredScopes : null;
    if (requiredScopes) {
      const scopes = store.state.auth.scopes;
      if (!scopes) {
        next('404');
        return;
      }
      const hasRequiredScope = requiredScopes.some(
        (scope) => scopes.indexOf(scope) >= 0,
      );
      if (!hasRequiredScope) {
        next('404');
        return;
      } else {
        next();
        return;
      }
    }

    next();
    return;
  },
  getCmsRoutes(routes) {
    const _cmsRoutes = [
      ...routes,
      {
        path: '/login',
        name: 'login',
        component: () => import('@/__stone/views/Login.vue'),
        meta: {
          template: 'auth',
        },
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('@/__stone/views/Logout.vue'),
        meta: {
          template: 'auth',
        },
      },
      {
        path: '/me',
        name: 'me',
        component: () => import('@/__stone/views/Me/Index.vue'),
        meta: {
          middleware: 'auth',
          template: 'app',
        },
      },
      {
        path: '/me/update',
        name: 'me_update',
        component: () => import('@/__stone/views/Me/Update.vue'),
        meta: {
          middleware: 'auth',
          template: 'app',
        },
      },
      {
        path: '/me/passwordupdate',
        name: 'me_passwordupdate',
        component: () => import('@/__stone/views/Me/PasswordUpdate.vue'),
        meta: {
          middleware: 'auth',
          template: 'app',
        },
      },
      {
        path: '/search-result',
        name: 'SearchResult',
        component: () => import('@/__stone/views/SearchResult.vue'),
        meta: {
          middleware: 'auth',
          template: 'app',
        },
      },
      {
        path: '/cms_topic/:topic',
        name: 'cms_topic',
        component: () => import('@/__stone/views/Topic.vue'),
        meta: {
          middleware: 'auth',
          template: 'app',
        },
      },

    ];
    _cmsRoutes.push(...this.getModuleRoutes())
    _cmsRoutes.push(
      ...[
        {
          path: '/*',
          name: 'notfound',
          component: () => import('@/__stone/views/Notfound.vue'),
        },
      ],
    );
    return _cmsRoutes;
  },
  getModuleRoutes() {
    const moduleRoutes = []
    const moduleFiles = this.getModulesJson()
    if (!moduleFiles) {
      return
    }
    for (const moduleKey in moduleFiles) {
      if (!moduleFiles[moduleKey].routes) {
        continue
      }
      moduleRoutes.push(
        ...moduleFiles[moduleKey].routes
      )
    }
    moduleRoutes.push(
      ...this.getExtendModulesRoutes()
    )
    return moduleRoutes
  },
  getExtendModulesRoutes() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    let extendRoutes = []
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const moduleKey = keyArr[1]
      if (!config.wsmodule[moduleKey]) {
        return
      }
      if (
        keyArr[2] == 'extend_modules' &&
        keyArr[4] == 'routes.js'
      ) {
        extendRoutes = [
          ...extendRoutes,
          ...modules(modulePath).default
        ]
      }
    });
    return extendRoutes
  },
  getModulesJson() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'routes.js') {
        moduleFiles[key].routes = modules(modulePath).default
      }
    });
    return moduleFiles
  },
  getResourceRoutes({
    routes = ['index'],
    modelName,
    pathName,
    filePrefix = '',
    pathPrefix = '',
    viewsPath = 'views',
    meta = {
      middleware: 'auth',
    },
    // generalTemplate = false
  }) {
    const _pathName = pathName ? pathName : modelName;
    const _routes = [];
    const _pascalCase = changeCase.pascalCase(modelName);
    if (routes.includes('index')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}`,
        name: `${modelName}_index`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Index.vue`),
        meta: meta,
      });
    }
    if (routes.includes('create')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}/create`,
        name: `${modelName}_create`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Create.vue`),
        meta: meta,
      });
    }
    if (routes.includes('update')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}/:id/update`,
        name: `${modelName}_update`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Update.vue`),
        meta: meta,
      });
    }
    if (routes.includes('read')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}/:id`,
        name: `${modelName}_read`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Read.vue`),
        meta: meta,
      });
    }
    if (routes.includes('version')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}/:id/version`,
        name: `${modelName}_version`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Version.vue`),
        meta: meta,
      });
    }
    return _routes;
  },
  getCrudTemplateRoutes({
    routes = ['index'],
    urlModelName,
    modelName,
    pathPrefix = '',
    middleware,
    meta = {},
  }) {
    const _urlModelName = urlModelName ? urlModelName : modelName;
    const _meta = {
      middleware: middleware,
      modelName: modelName,
      urlModelName: _urlModelName,
      ...meta,
    };
    const _routes = [];
    if (routes.includes('order')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/order`,
        name: `${_urlModelName}_order`,
        component: () =>
          import(`@/__stone/templates/TemplateModelOrder.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('index_v2')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}`,
        name: `${_urlModelName}_index`,
        component: () =>
          import(`@/__stone/templates/TemplateModelIndex.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('index')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}`,
        name: `${_urlModelName}_index`,
        component: () =>
          import(`@/__stone/templates/TemplateModelIndexDeprecate.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('update')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/:id/update`,
        name: `${_urlModelName}_update`,
        component: () =>
          import(`@/__stone/templates/TemplateModelUpdate.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('create')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/create`,
        name: `${_urlModelName}_create`,
        component: () =>
          import(`@/__stone/templates/TemplateModelCreate.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('batchcreate')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/batchcreate`,
        name: `${_urlModelName}_batchcreate`,
        component: () =>
          import(`@/__stone/templates/TemplateModelBatchCreate.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('read')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/:id`,
        name: `${_urlModelName}_read`,
        component: () =>
          import(`@/__stone/templates/TemplateModelRead.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('version')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/:id/version`,
        name: `${_urlModelName}_version`,
        component: () =>
          import(`@/__stone/templates/TemplateModelVersion.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('index_my')) {
      _routes.push({
        path: `${pathPrefix}/my/${_urlModelName}`,
        name: `${_urlModelName}_index_my`,
        component: () =>
          import(`@/__stone/templates/TemplateModelIndexMy.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('create_my')) {
      _routes.push({
        path: `${pathPrefix}/my/${_urlModelName}/create`,
        name: `${_urlModelName}_create_my`,
        component: () =>
          import(`@/__stone/templates/TemplateModelCreateMy.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('ws_class')) {
      _routes.push({
        path: `/${_urlModelName}_ws_class`,
        name: `/${_urlModelName}_ws_class`,
        component: () =>
          import(`@/__stone/templates/TemplateModelWsClass.vue`),
        meta: _meta,
      });
    }
    return _routes;
  },
  getApiUrl() {
    if (process.env.VUE_APP_API_BASE_URL) {
      return process.env.VUE_APP_API_BASE_URL;
    }
    const hostname = window.location.hostname
    let scheme = 'https'
    if (process.env.VUE_APP_API_SCHEME) {
      scheme = process.env.VUE_APP_API_SCHEME
    }
    // @Q@
    if (hostname == 'localhost') {
      return `${scheme}://localhost:${process.env.VUE_APP_API_PORT}/api`
    } else if (hostname == '127.0.0.1') {
      return `${scheme}://127.0.0.1:${process.env.VUE_APP_API_PORT}/api`
    } else {
      return `${scheme}://api-${hostname}/api`
    }
  }
};
