<template>
  <div class="ws-state-has-many">

  </div>
</template>

<script>
export default {
  computed: {
    _createHideFields() {
      return [this.belongsToKey];
    },
    _updateHideFields() {
      return [this.belongsToKey];
    },
    _params() {
      return {
        [this.belongsToKey]: this.belongsToValue,
      };
    },
    _createData() {
      return {
        [this.belongsToKey]: this.belongsToValue,
      };
    },
    _updateData() {
      return {
        [this.belongsToKey]: this.belongsToValue,
      };
    },
  },
  props: {
    modelName: {
      type: String,
      default: null,
    },
    showFields: {
      type: Array,
    },
    fields: {
      type: Object,
    },
    params: {
      type: Object,
    },
    belongsToKey: {
      type: String,
    },
    belongsToValue: {},
  },
};
</script>

<style>
</style>