import config from '@/__config'

const _routes = [
]

if (config.wsmodule.file_system.drive) {
  _routes.push(...[
    {
      path: '/drive',
      name: 'drive',
      component: () =>
        import('@/__modules/file_system/views/Drive/Index.vue'),
    },
    {
      path: '/drive/folder/:folder_id',
      name: 'drive_folder',
      component: () =>
        import('@/__modules/file_system/views/Drive/Folder/Index.vue'),
    },
  ])

}

export default _routes