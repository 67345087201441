export default {
  testing_mail: {
    icon: 'icon-md-email',
    title: '信箱寄送',
    link: '/testing/mail',
    scopes: ['testing-admin'],
  },
  testing_app_notify: {
    icon: 'icon-md-phone-iphone',
    title: 'App 通知',
    scopes: ['testing-admin'],
    link: '/testing/fcm',
  },
  testing_case: {
    icon: 'icon-md-assignment',
    title: 'Test Case',
    scopes: ['testing-admin'],
    menu: [
      {
        title: 'Test Case Template',
        link: '/testing_case_template',
        scopes: ['testing-admin'],
      },
    ],
  },
}