<template>
  <div class="ws-file-state-image">
    <WsImg
      v-if="value"
      :url="value"
      alt=""
      class="mb-10"
      :wireBackground="true"
      :previewMaxWidth="previewMaxWidth"
      :previewMaxHeight="previewMaxHeight"
    />
    <FileSystemFilePickerFile
      :signed="signed"
      :storageTarget="storageTarget"
      ref="FileSystemFilePickerFile"
      :maxSize="maxSize"
      @submit="$emit('input',$event)"
    >
    </FileSystemFilePickerFile>
    <WsBtn @click="$_onPickDialogOpen()">選擇檔案</WsBtn>
    <WsIconBtn
      @click="$_onClear()"
      v-if="value"
      class="remove-btn"
      backgroundColor="#000"
      name="icon-md-delete"
    />
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    $_onClear() {
      this.$emit("input", null);
    },
    $_onPickDialogOpen() {
      this.$refs.FileSystemFilePickerFile.open();
    },
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
    storageTarget: {},
    maxSize: {
      default: 5 * 1024 * 1024,
    },
  },
};
</script>

<style></style>