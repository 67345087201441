<template>
  <WsMain>
    <WsFlex>
      <div class="w-16">
        <WsStateForm
          v-model="filter.state"
          :fields="filter.fields"
        ></WsStateForm>
      </div>
      <WsAnalyticsGroup
        class="w-full"
        :items="analytics"
        :params="_params"
      ></WsAnalyticsGroup>
    </WsFlex>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        state: {},
        fields: {
          admin: {
            type: "belongsTo",
            label: "後台使用者",
            textKey: "name",
            extendParams: {
              is_active: 1,
            },
            modelName: this.$o_o.$c.wsmodule.admin.admin_blur
              ? "cmser"
              : "admin",
          },
          xc_project: {
            type: "belongsTo",
            label: "計畫",
            textKey: "name",
            modelName: "xc_project",
          },
        },
      },
      analytics: [
        {
          title: "Done Task 數量",
          type: "sum",
          fetchUrl: "/calc/sum/xc_task_record/done_count",
          fields: {
            created_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "Done Task 數量",
          type: "trending",
          fetchUrl: "/calc/trending/xc_task_record/done_count",
          fields: {
            created_at: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "實際執行時間總計",
          type: "sum",
          fetchUrl: "/calc/sum/xc_task/done_time_amount",
          fields: {
            done_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "實際執行時間總計",
          type: "trending",
          fetchUrl: "/calc/trending/xc_task/done_time_amount",
          fields: {
            done_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估執行時間總計",
          type: "sum",
          fetchUrl: "/calc/sum/xc_task/tr_time_amount",
          fields: {
            tr_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估執行時間總計",
          type: "trending",
          fetchUrl: "/calc/trending/xc_task/tr_time_amount",
          fields: {
            tr_at: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "請假",
          type: "sum",
          fetchUrl: "/calc/sum/xc_leave_day/off_count",
          fields: {
            start_date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "請假",
          type: "trending",
          fetchUrl: "/calc/trending/xc_leave_day/off_count",
          fields: {
            start_date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
      ],
    };
  },
  computed: {
    _params() {
      return {
        taker: this.filter.state.admin?.id,
        admin: this.filter.state.admin?.id,
        xc_project: this.filter.state.xc_project?.id,
      };
    },
  },
};
</script>