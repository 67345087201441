<template>
  <WsMain class="off-day-remain-hours">
    <WsStateForm
      v-model="filter.state"
      :fields="filter.fields"
      @input="$_input"
      class="mb-10"
    />
    <WsActionApi
      v-if="xc_employee_id"
      :fetchUrl="`xc_employee/${xc_employee_id}/overview/xc_leave_day`"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching" />
      <XcLeaveDayOverview
        v-else
        :overview="res"
      />
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: [],
      xc_employee_id: null,
      filter: {
        state: {},
        fields: {
          xc_employee: {
            type: "belongsTo",
            label: "申請人",
            textKey: "name",
            modelName: "xc_employee",
          },
        },
      },
    };
  },
  methods: {
    $_input($event) {
      this.xc_employee_id = $event?.xc_employee?.id || null;
    },
  },
};
</script>
