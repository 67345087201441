/<template>
  <div>
    <WsActionApi
      fetchUrl="my/overview/xc_leave_day"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <WsBoardCard001
        v-else
        v-bind="$_getCardProps(res)"
      />
    </WsActionApi>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTypes: [
        "annual_leave",
        "sick_leave",
        "personal_leave",
        "annual_leave_from_work_overtime",
      ],
    };
  },
  methods: {
    $_getCardProps(res) {
      if (!res) {
        return {};
      }
      // if (!res || !res.xc_work_overtime) return {};
      console.log("res", res);
      return {
        items: [
          {
            label: "可用休假餘額",
            items: this.showTypes.map((showType) => ({
              label: res[showType].name,
              value: `${res[showType].total_days_and_hours_days}天 ${res[showType].total_days_and_hours_hours}小時`,
            })),
          },
          {
            label: "已用休假餘額",
            items: this.showTypes.map((showType) => ({
              label: res[showType].name,
              value: `${res[showType].used_days_and_hours_days}天 ${res[showType].used_days_and_hours_hours}小時`,
            })),
          },
        ],
        urlText: "查看所有假別餘額",
        url: "/my/leave_day_remain_hours",
      };
    },
  },
};
</script>
