<template>
  <WsFlex alignItems="flex-start">
    <XcTaskStatusLabel
      class="mt-4"
      :status="xc_task.status"
    ></XcTaskStatusLabel>
    <WsLink
      class="ml-8"
      :to="`/xc_task/${xc_task.id}`"
    >
      <WsText>#{{ xc_task.id }} {{ xc_task.name }}</WsText>
    </WsLink>
  </WsFlex>
</template>

<script>
export default {
  props: {
    xc_task: {},
  },
};
</script>

<style>
</style>