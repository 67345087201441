<template>
  <WsMain>
    <ValidationObserver ref="form">
      <form @submit.prevent></form>
      <WsStateForm
        :fields="_stateFields"
        v-model="state"
      ></WsStateForm>
    </ValidationObserver>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(`/xc_employee/${this._id}/setadmin`, this.state);
        this.$router.push(`/xc_employee`);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
    _stateFields() {
      return {
        email: this.$store.state.stone_model.admin.fields.email,
        password: {
          ...this.$store.state.stone_model.admin.fields.password,
          rules: "required|min:6",
        },
      };
    },
  },
};
</script>

<style>
</style>