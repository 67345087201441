<template>
  <div>
    <WsCreate
      ref="WsCreate"
      :modelName="$store.state.stone_model.xc_employee_salary_adjustjob.modelName"
      :label="$store.state.stone_model.xc_employee_salary_adjustjob.label"
      :fields="$store.state.stone_model.xc_employee_salary_adjustjob.fields"
      :primary="_primary"
      v-model="state"
      :extendPostData="extendPostData"
      @complete="$_onComplete($event)"
      :sumbitFormData="true"
      :emitComplete="true"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      extendPostData: {
        source: "upload",
      },
    };
  },
  methods: {
    $_onComplete() {
      this.$router.replace(`/xc_employee_salary_adjustjob`);
    },
  },
  computed: {
    _primary() {
      const _primary = [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                // "review_xc_employee",
                "effective_at",
                "file",

                // "source"
              ],
            },
          ],
        },
      ];
      // if (this.state.source) {
      //   if (this.state.source === "xc_employees") {
      //     _primary[0].floors[0].fields.push("xc_employees");
      //   } else if (this.state.source === "xc_employee_department") {
      //     _primary[0].floors[0].fields.push("xc_employee_department");
      //   }
      // }
      return _primary;
    },
  },
};
</script>