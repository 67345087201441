<template>
  <div
    class="ws-flex"
    :class="[
      {'is-wrap':isWrap},
      {'is-inline':isInline},
    ]"
    :style="[
      {'flex-direction':flexDirection},
      {'align-items':alignItems},
      {'justify-content':justifyContent},
      {'gap':_gap}
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  computed: {
    _gap() {
      // check if gap is a number
      if (!this.gap) {
        return;
      }
      if (typeof this.gap === "number") {
        return `${this.gap}px`;
      } else {
        return this.gap;
      }
    },
  },
  props: {
    flexDirection: {
      type: String,
    },
    isInline: {
      type: Boolean,
    },
    isWrap: {
      type: Boolean,
      default: false,
    },
    alignItems: {
      type: String,
    },
    justifyContent: {
      type: String,
    },
    gap: {},
  },
};
</script>