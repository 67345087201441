<template>
  <div>
    <WsIconBtn
      tooltip="貼上 Embed Code"
      @click="$refs.WsPopup.open()"
      name="icon-sm-instagram"
      :noBorder="noBorder"
    />
    <WsPopup
      title="貼上 Embed Code"
      ref="WsPopup"
    >
      <template v-slot:content>
        <WsState
          v-model="state"
          autofocus
        />
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onCancel()"
          outlined
        >{{ $t('cancel') }}</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          color="primary"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsPopup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: null,
    }
  },
  methods: {
    $_onCancel() {
      this.state = null
      this.$refs.WsPopup.close()
    },
    $_onSubmit() {
      if (this.state) {
        this.$emit('setEmbed', this.state)
      }
      this.$_onCancel()
    },
  },
  props: {
    noBorder: {},
  }
}
</script>
