<template>
  <div
    class="testing-result-label"
    :class="[_resultText]"
  >{{_resultText}}</div>
</template>

<script>
export default {
  props: {
    status: {},
    value: {},
  },
  computed: {
    _status() {
      if (this.status) {
        return this.status;
      } else {
        return this.value;
      }
    },
    _resultText() {
      const _items = [
        { label: "Wait", value: "wait" },
        { label: "Pass", value: "pass" },
        { label: "Fail", value: "fail" },
      ];
      const _item = _items.find((e) => {
        return String(e.value) == String(this._status);
      });
      return _item ? _item.label : "-";
    },
  },
};
</script>

<style lang="scss">
.testing-result-label {
  min-width: 50px;
  font-size: 0.75rem;
  text-align: center;
  line-height: 1;
  background-color: var(--gray);
  padding: 6px 8px;
  border-radius: 20px;
  &.Wait {
    background-color: var(--gray4d);
  }
  &.Pass {
    background-color: var(--primary);
  }
  &.Fail {
    background-color: var(--danger);
  }
}
</style>