<template>
  <ValidationProvider
    :rules="rules"
    v-slot="{ errors }"
    class="ws-state"
    :mode="validationMode"
    :vid="vid"
    :class="[
      arrange,
      { 'is-focus': isFocus },
      { 'has-content': value },
    ]"
  >
    <div class="ws-state__flex-container">
      <div class="ws-state__label-wrap">
        <div class="ws-state__label-leftwrap">
          <WsText
            v-if="_label"
            class="ws-state__label"
            :size="labelSize"
          >{{ _label }} {{ labelDes }}<span
              v-if="$_hasRule('required')"
              :style="{ color: $color.danger }"
            >*</span>
          </WsText>
          <WsTooltip
            v-if="tooltip"
            :value="$t(tooltip)"
          >
            <WsIcon
              class="ws-state__tooltip"
              name="icon-ws-outline-help-outline"
            />
          </WsTooltip>
        </div>
        <WsText
          v-if="maxlength"
          :size="labelSize"
          class="ws-state__word_count"
        >
          {{ _word_count }}
        </WsText>
      </div>
      <div
        v-if="!readonly"
        class="ws-state__content"
      >
        <WsStateInput
          v-if="type == 'text' || type == 'password' || type == 'email' || type == 'link' || type == 'number' || type == 'currency'"
          ref="WsStateInput"
          @keyup="$_onKeyup"
          :autofocus="autofocus"
          :maxWidth="maxWidth"
          :rules="rules"
          :type="type"
          :name="name"
          :errors="errors"
          :placeholder="placeholder"
          :note="note"
          :isOk="isOk"
          :errorMessage="errorMessage"
          :disabled="disabled"
          :value="value"
          :maxlength="maxlength"
          :stateData="stateData"
          :suffixText="suffixText"
          :prefix="prefix"
          :suffix="suffix"
          :step="step"
          @onFocus="$_onFocus()"
          @onBlur="$_onBlur()"
          @input="$emit('input', $event)"
          @enter="$emit('submit', $event)"
        ></WsStateInput>
        <WsStateSearch
          v-if="type == 'search'"
          ref="WsStateInput"
          @keyup="$_onKeyup"
          :autofocus="autofocus"
          :maxWidth="maxWidth"
          :rules="rules"
          :type="type"
          :name="name"
          :errors="errors"
          :placeholder="placeholder"
          :searchBtnText="searchBtnText"
          :note="note"
          :isOk="isOk"
          :errorMessage="errorMessage"
          :disabled="disabled"
          :value="value"
          :maxlength="maxlength"
          :stateData="stateData"
          :suffixText="suffixText"
          :mode="mode"
          @input="$emit('input', $event)"
          @enter="$emit('submit', $event)"
          @search="$emit('search', $event)"
        ></WsStateSearch>
        <WsStateTextarea
          v-else-if="type == 'textarea'"
          :value="value"
          :autoGrow="autoGrow"
          :placeholder="placeholder"
          @input="$emit('input', $event)"
          @keyup="$_onKeyup"
          @onFocus="$_onFocus()"
          @onBlur="$_onBlur()"
          :autofocus="autofocus"
        ></WsStateTextarea>
        <WsStateEditor
          v-else-if="type == 'editor'"
          :value="value"
          v-bind="$props"
          @input="$emit('input', $event)"
        ></WsStateEditor>
        <WsStateModelSelect
          v-else-if="type == 'belongsTo'"
          v-bind="$props"
          @input="$emit('input', $event)"
        ></WsStateModelSelect>
        <WsStateTable
          v-else-if="type == 'table'"
          v-bind="$props"
          @input="$emit('input', $event)"
        >
        </WsStateTable>
        <WsStateModelSelect
          v-else-if="type == 'belongsToMany'"
          v-bind="$props"
          @input="$emit('input', $event)"
          multiple
        ></WsStateModelSelect>
        <WsStateBelongsToManyMultiLayers
          v-else-if="type == 'belongs-to-many-multi-layers'"
          :placeholder="placeholder"
          :value="value"
          :extendParams="extendParams"
          @input="$emit('input', $event)"
          :layerFields="layerFields"
          :showTags="showTags"
        ></WsStateBelongsToManyMultiLayers>
        <WsStateSelectTwoLayer
          v-else-if="type === 'two-layer-select'"
          :value="value"
          outline
          :menu="items"
          :placeholder="placeholder"
          :all-select-text="selectAllText"
          @input="$emit('input', $event)"
        ></WsStateSelectTwoLayer>
        <WsStateSwitch
          v-else-if="type == 'switch'"
          :activeText="activeText"
          :inactiveText="inactiveText"
          :value="value"
          :showText="showText"
          @input="$emit('input', $event)"
        ></WsStateSwitch>
        <WsStateDate
          v-else-if="type == 'date'"
          :value="value"
          :startDate="startDate"
          :disabledDate="disabledDate"
          :modelData="stateData"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="$emit('input', $event)"
        ></WsStateDate>
        <WsStateDateRangeOrNot
          v-else-if="type == 'date-range-or-not'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateDateRangeOrNot>
        <WsStateDateRange
          v-else-if="type == 'date-range'"
          :value="value"
          :startDate="startDate"
          :disabledDate="disabledDate"
          :modelData="stateData"
          :disabled="disabled"
          :placeholder="placeholder"
          :shortcuts="shortcuts"
          @input="$emit('input', $event)"
        ></WsStateDateRange>
        <WsStateDatetime
          v-else-if="type == 'datetime'"
          @input="$emit('input', $event)"
          v-bind="$props"
        ></WsStateDatetime>
        <WsStateTime
          v-else-if="type == 'time'"
          v-bind="$props"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateTime>
        <WsStateLocales
          v-else-if="type == 'locales'"
          :fields="fields"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateLocales>
        <WsStatePocketFile
          v-else-if="type == 'pocket_file'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :originValue="originValue"
          @input="$emit('input', $event)"
        ></WsStatePocketFile>
        <WsStateAudio
          v-else-if="type == 'audio'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateAudio>
        <WsStateSystemAudio
          v-else-if="type == 'system_audio'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateSystemAudio>
        <WsStateUploadFile
          v-else-if="type == 'upload_file'"
          @input="$emit('input', $event)"
          v-bind="$props"
        ></WsStateUploadFile>
        <WsStateFile
          v-else-if="type == 'file'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input', $event)"
        ></WsStateFile>
        <WsStateFiles
          v-else-if="type == 'files'"
          :value="value"
          :signed="signed"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :getUploadUrl="getUploadUrl"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input', $event)"
        ></WsStateFiles>
        <WsStateTags
          v-else-if="type == 'tags'"
          :value="value"
          v-bind="$props"
          :inputType="inputType"
          :target="target"
          @input="$emit('input', $event)"
          :placeholder="placeholder"
        ></WsStateTags>
        <WsStateSelect
          v-else-if="type == 'select'"
          v-bind="$props"
          @input="$emit('input', $event)"
          @update:des="$_onUpdateDes($event)"
        ></WsStateSelect>
        <WsStateSelect
          v-else-if="type == 'multi-select'"
          :items="items"
          :value="value"
          :modelName="modelName"
          :textKey="textKey"
          :valueKey="valueKey"
          multiple
          :searchable="searchable"
          :selectAll="selectAll"
          :placeholder="placeholder"
          @input="$emit('input', $event)"
        ></WsStateSelect>
        <WsStateScope
          v-else-if="type == 'scope'"
          :value="value"
          :textKey="textKey"
          :valueKey="valueKey"
          multiple
          :searchable="searchable"
          :selectAll="selectAll"
          :placeholder="placeholder"
          @input="$emit('input', $event)"
          :items="items"
        ></WsStateScope>
        <WsStateSelectMultiLayers
          v-else-if="type == 'select-multi-layers'"
          :items="items"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateSelectMultiLayers>
        <WsStateRadio
          v-else-if="type == 'radio'"
          :items="items"
          :value="value"
          :nullable="nullable"
          @input="$emit('input', $event)"
        ></WsStateRadio>
        <WsStateRadioForm
          v-else-if="type == 'radio-input'"
          :items="items"
          :value="value"
          :stateData="stateData"
          @input="$emit('input', $event)"
          @form-input="$emit('form-input', $event)"
        ></WsStateRadioForm>
        <WsStatePocketImage
          v-else-if="type == 'pocket_image'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :originValue="originValue"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input', $event)"
        ></WsStatePocketImage>
        <WsStateFileImage
          v-else-if="type == 'file_image'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          :storageTarget="storageTarget"
          @input="$emit('input', $event)"
        ></WsStateFileImage>
        <WsStateFileImages
          v-else-if="type == 'file_images'"
          :value="value"
          :signed="signed"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :getUploadUrl="getUploadUrl"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          :storageTarget="storageTarget"
          @input="$emit('input', $event)"
        ></WsStateFileImages>
        <WsStateFileFile
          v-else-if="type == 'file_file'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :storageTarget="storageTarget"
          @input="$emit('input', $event)"
        ></WsStateFileFile>
        <WsStateFileFiles
          v-else-if="type == 'file_files'"
          :value="value"
          :signed="signed"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :getUploadUrl="getUploadUrl"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          :storageTarget="storageTarget"
          @input="$emit('input', $event)"
        ></WsStateFileFiles>
        <WsStateImage
          v-else-if="type == 'image'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          :storageTarget="storageTarget"
          @input="$emit('input', $event)"
        ></WsStateImage>
        <WsStateImages
          v-else-if="type == 'images'"
          :value="value"
          :signed="signed"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :getUploadUrl="getUploadUrl"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          :sortable="sortable"
          @input="$emit('input', $event)"
        ></WsStateImages>
        <WsStateColor
          v-else-if="type == 'color'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateColor>
        <WsStateTextArray
          v-else-if="type == 'text_array'"
          @input="$emit('input', $event)"
          v-bind="$props"
        />
        <WsStateModelBatch
          v-else-if="type == 'hasMany'"
          v-bind="$props"
          @input="$emit('input', $event)"
        />
        <WsStateList
          v-else-if="type == 'list'"
          @input="$emit('input', $event)"
          v-bind="$props"
          :showFields="_showFields"
        >
          <template v-slot:extendCols>
            <slot name="extendCols"></slot>
          </template>
        </WsStateList>
        <WsStateFields
          v-else-if="type == 'fields'"
          :value="value"
          @input="$emit('input', $event)"
        >
        </WsStateFields>
        <WsStateSort
          v-else-if="type == 'sort'"
          :value="value"
          :layerFields="layerFields"
          :labelKey="labelKey"
          @input="$emit('input', $event)"
        >
          <template #slick-item="props">
            <slot
              name="slick-item"
              v-bind="props"
            ></slot>
          </template>
        </WsStateSort>
        <WsStateRules
          v-else-if="type == 'rules'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateRules>
        <WsStateForm
          v-else-if="type == 'form'"
          :fields="fields"
          :value="value"
          @input="$emit('input', $event)"
          :errorMessages="errorMessages"
          :defaultValueOnEmpty="defaultValueOnEmpty"
        ></WsStateForm>
        <WsStateForm
          v-else-if="type == 'payload'"
          :fields="fields"
          :value="value"
          @input="$emit('input', $event)"
          :errorMessages="errorMessages"
          :defaultValueOnEmpty="defaultValueOnEmpty"
        ></WsStateForm>
        <WsStateCheckbox
          v-else-if="type == 'checkbox'"
          :value="value"
          :text="text"
          @input="$emit('input', $event)"
        ></WsStateCheckbox>
        <WsStateCheckboxs
          v-else-if="type == 'checkboxs'"
          :value="value"
          :items="items"
          @input="$emit('input', $event)"
        ></WsStateCheckboxs>
        <WsStateWasaIntro
          v-else-if="type == 'wasaintro'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateWasaIntro>
        <WsStateBusinessHours
          v-else-if="type == 'business_hours'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateBusinessHours>
        <WsStateAvailableTime
          v-else-if="type == 'available_time'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateAvailableTime>
        <WsStateListInfinite
          v-else-if="type == 'list-infinite'"
          :createBtnText="createBtnText"
          :fields="fields"
          :value="value"
          :creatable="creatable"
          :initValueLength="initValueLength"
          :valueLengthLimit="valueLengthLimit"
          @input="$emit('input', $event)"
        ></WsStateListInfinite>
        <WsStateConfig
          v-else-if="type == 'config'"
          :fields="fields"
          :value="value"
          :label="label"
          :checkable="checkable"
          :exportable="exportable"
          @input="$emit('input', $event)"
        ></WsStateConfig>
        <WsStateBoolean
          v-else-if="type == 'boolean'"
          :value="value"
          :label="label"
          @input="$emit('input', $event)"
        ></WsStateBoolean>
        <WsStateYear
          v-else-if="type == 'year'"
          :value="value"
          :range="range"
          @input="$emit('input', $event)"
        ></WsStateYear>
        <WsStateEmails
          v-else-if="type == 'emails'"
          :value="value"
          @input="$emit('input', $event)"
          :placeholder="placeholder"
        ></WsStateEmails>
        <WsStateJson
          v-else-if="type == 'json'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateJson>
        <WsStateMapJson
          v-else-if="type == 'mapJson'"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateMapJson>
        <WsStateLocaleMessage
          v-else-if="type == 'locale_message'"
          :items="items"
          :value="value"
          @input="$emit('input', $event)"
        ></WsStateLocaleMessage>
        <WsStateIconPicker
          v-else-if="type == 'icon-picker'"
          :items="items"
          :value="value"
          @input="$emit('input', $event)"
        />
        <WsStateSpecSetting
          v-else-if="type == 'spec-setting'"
          :items="items"
          :value="value"
          :stateData="stateData"
          :createSpecSettings="createSpecSettings"
          :specFieldName="specFieldName"
          @input="$emit('input', $event)"
          @form-input="$emit('form-input', $event)"
        />
        <WsStateHourRanges
          v-else-if="type == 'hour_ranges'"
          :value="value"
          @input="$emit('input', $event)"
        />
        <input
          type="hidden"
          :value="value"
        >
        <WsText
          class="mt-12"
          size="14"
          v-if="preDes"
        >{{ preDes }}</WsText>
        <WsText
          class="mt-12"
          size="14"
          v-if="des"
        >{{ des }}</WsText>
        <WsText
          v-if="hint"
          class="mt-6"
          :size="12"
        >
          {{ hint }}
        </WsText>
        <WsErrorMessage
          class="mt-8"
          v-if="errors && errors.length"
        >{{ errors[0] }}</WsErrorMessage>
        <div v-if="errorMessage && errorMessage.length && (typeof errorMessage == 'array' || typeof errorMessage == 'object')">
          <WsErrorMessage
            v-for="(errorMessageItem, errorMessageItemIndex) in errorMessage"
            :key="errorMessageItemIndex"
            class="mt-8"
          >{{ $t(errorMessageItem) }}</WsErrorMessage>
        </div>
        <WsErrorMessage
          class="mt-8"
          v-else-if="errorMessage"
        >{{ $t(errorMessage) }}</WsErrorMessage>
      </div>
      <div v-else>
        <WsInfo
          :type="type"
          :value="value"
          :items="items"
          :warnText="warnText"
        />
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { message } from "@/__stone";
import { ValidationProvider, extend } from "vee-validate";
import {
  alpha_num,
  confirmed,
  email,
  integer,
  is_not,
  max,
  max_value,
  min,
  min_value,
  required,
} from "vee-validate/dist/rules";
extend("max", {
  ...max,
  // message: "不得多於{length}位"
  message: message["validate-max"],
});
extend("min", {
  ...min,
  // message: "不得少於{length}位"
  message: message["validate-min"],
});
extend("min_value", {
  ...min_value,
  message: "不得少於{min}",
});
extend("max_value", {
  ...max_value,
  message: "不得大於{max}",
});
extend("required", {
  ...required,
  // message: "此項目為必填"
  message: message["validate-required"],
});
extend("email", {
  ...email,
  // message: "email格式不符"
  message: message["validate-email"],
});
extend("alpha_num", {
  ...alpha_num,
  message: "輸入文字必須為英文或數字",
});
extend("integer", {
  ...integer,
  message: "請輸入整數",
});
extend("is_not", {
  ...is_not,
  message: "不可為 {other}",
});
extend("int_or_de", {
  params: ["num"],
  validate: (value, { num = 0 }) => {
    const regex = new RegExp(`^(-)?\\d+(\\.\\d{1,${num}})?$`);
    return regex.test(value);
  },
  message: `請輸入正確的值，整數或小數，小數點後只能後{num}位`,
});

extend("uppercase", {
  validate: (value) => /[A-Z]/.test(value),
  message: "至少須包含一個大寫字母",
});

extend("lowercase", {
  validate: (value) => /[a-z]/.test(value),
  message: "至少須包含一個小寫字母",
});

extend("digits", {
  validate: (value) => /\d/.test(value),
  message: "至少須包含一個數字",
});

extend("no-special-chars", {
  validate: (value) => /^[^\W_]+$/.test(value),
  message: "不可包含特殊字元",
});
extend("confirmed", {
  ...confirmed,
  message: "內容不符合 {target}",
});

export default {
  name: "WsState",
  data() {
    return {
      preDes: "",
      des: "",
      isFocus: false,
    };
  },
  components: {
    ValidationProvider,
  },
  computed: {
    _label() {
      if (this.displayLabel) {
        if (this.labelInLocale) {
          return this.$t(this.label);
        } else if (this.type == "config") {
          return null;
        } else if (this.type == "boolean") {
          return null;
        } else {
          return this.$t(this.label);
        }
      } else {
        return null;
      }
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else if (this.fields) {
        const _showFields = [];
        for (let fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      } else {
        return [];
      }
    },
    _word_count() {
      let _value = this.value ? this.value.length : 0;
      return `${_value}/${this.maxlength}`;
    },
  },
  methods: {
    async $_setPreDes() {
      if (this.setPreDes) {
        this.setPreDes(this);
      }
    },
    $_onUpdateDes($event) {
      this.des = $event;
    },
    $_hasRule(rule) {
      if (!this.rules) {
        return false;
      }
      const _rules = this.rules.split("|");
      const exist = _rules.find((e) => {
        return e == rule;
      });
      return exist;
    },
    $_onFocus() {
      this.isFocus = true;
    },
    $_onBlur() {
      this.isFocus = false;
    },
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
    focus() {
      if (
        this.type == "text" ||
        this.type == "email" ||
        this.type == "search" ||
        this.type == "password"
      ) {
        if (this.$refs.WsStateInput) {
          this.$refs.WsStateInput.focus();
        }
      }
    },
  },
  mounted() {
    this.$_setPreDes();
    if (this.autofocus) {
      if (this.$refs.WsStateInput) {
        this.$refs.WsStateInput.$el.focus();
      }
    }
    if (this.value == undefined && this.defaultValue != undefined) {
      this.$emit("input", this.defaultValue);
    }
  },
  props: {
    setPreDes: {},
    getImportformatDownloadUrl: {},
    warnText: {},
    format: {},
    disableBefore: {
      type: Boolean,
    },
    searchable: {
      type: Boolean,
    },
    optionAll: {
      type: Boolean,
    },
    vid: {
      type: String,
    },
    extendParams: {
      type: Object,
    },
    labelDes: {
      type: String,
    },
    startDate: {
      type: String,
    },
    belongsToKey: {
      type: String,
    },
    belongsToValue: {},
    params: {
      type: Object,
    },
    defaultValueOnEmpty: {},
    arrange: {
      type: String,
      default: "column",
    },
    range: {
      type: Array,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    showFields: {
      type: Array,
      default: null,
    },
    originValue: {},
    displayLabel: {
      type: Boolean,
      default: true,
    },
    getText: {
      type: Function,
      default: null,
    },
    textKey: {
      type: [String, Array],
    },
    requiredFieldState: {},
    requiredField: {
      type: String,
      default: null,
    },
    autoGrow: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    uploadUrl: {
      type: String,
      default: null,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    activeText: {
      type: String,
      default: "Y",
    },
    inactiveText: {
      type: String,
      default: "N",
    },
    conditions: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: [String, Number],
    },
    maxHeight: {
      type: String,
    },
    previewMaxWidth: {
      type: [String, Number],
    },
    previewMaxHeight: {
      type: [String, Number],
    },
    fields: {
      type: [Object, Array],
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: [String, Array],
      default: null,
    },
    errorMessages: {
      type: [Object, String],
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Array],
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    parentState: {},
    parent: {
      type: String,
      default: null,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "default",
    },
    staticUrl: {
      type: Boolean,
      default: false,
    },
    postData: {
      type: Object,
      default: null,
    },
    requestMethod: {
      type: String,
      default: "put",
    },
    labelSize: {
      type: [String, Number],
      default: 14,
    },
    valueKey: {
      type: String,
    },
    text: {
      type: String,
    },
    validationMode: {
      type: String,
      default: "passive",
    },
    labelInLocale: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [Number, String],
    },
    stateData: {
      // type: Object,
    },
    customItems: {
      type: Function,
    },
    allSelectText: {
      type: String,
    },
    selectAll: {
      type: Boolean,
    },
    hasCountryCode: {
      type: Boolean,
    },
    disabledDate: {
      type: Function,
    },
    createBtnText: {
      type: String,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    initValueLength: {
      type: Number,
      default: 1,
    },
    valueLengthLimit: {
      type: Number,
    },
    hint: {
      type: String,
    },
    layerFields: {
      type: Array,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    pocketable: {
      type: Boolean,
      default: true,
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
    checkable: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
    },
    defaultValue: {},
    suffixText: {
      type: String,
    },
    searchBtnText: {
      type: String,
    },
    nullable: {
      type: Boolean,
    },
    showText: {
      type: Boolean,
    },
    inputType: {
      type: String,
    },
    hourStep: {},
    minuteStep: {},
    secondStep: {},
    target: {},
    imageClickable: {},
    fetchUrl: {},
    shortcuts: {
      type: Array,
      default() {
        return [];
      },
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
    storageTarget: {},
    step: Number,
    labelKey: String,
    mentionable: {},
    tooltip: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    specFieldName: String,
    toolbarActions: {},
    createSpecSettings: {
      type: [Boolean, Number],
    },
    editable: {},
    maxSize: {},
  },
  watch: {
    value: {
      handler() {
        this.$_setPreDes();
      },
      deep: true,
    },
  },
};
</script>