import config from '@/__config'

export default {
  getXcProjectMenu(data, res) {
    if (!config.wsmodule.xc_project.file) {
      return res
    }
    res.splice(1, 0, {
      title: "檔案",
      link: `/xc_project/${data.id}/drive`,
    });
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    if (!config.wsmodule.xc_project.file) {
      return res
    }
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'drive',
        name: 'drive',
        component: () => import('@/__modules/file_system/extend_modules/xc_project/views/XcProject/Drive/Index.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'drive/folder/:folder_id',
        name: 'drive_folder',
        component: () => import('@/__modules/file_system/extend_modules/xc_project/views/XcProject/Drive/Folder/Index.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}